export class Usuario extends Object 
{
    constructor(
        public id_user: string,
        public email: string,
        public name: string,
        public centro: string,
        public devices: string[],
        public projects: string[],
        public version_sucre: string
    ) 
    {
        super();
    }
}

export class Proyecto extends Object 
{
    constructor(
        public _id: string,
        public id_user: string,
        public project_name: string,
        public centro: string,
        public xml: string,
        public mod_date: string,
        public url_img: string,
        public project_description: string,
        public tags: string[],
        public version_sucre: string
    )
    {
        super();
    }
}

export class Admins extends Object 
{
    constructor(
        public user_id: string[]) 
    {
        super();
    }
}

export class Centro extends Object 
{
    constructor(
        public _id: string,
        public id_user_admin: string,
        public email: string,
        public nombre: string,
        public tags: string[]) 
    {
        super();
    }
}
