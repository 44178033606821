import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';


//HASH LOCATION STRATEGY
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
//END 

//firebase-authentication
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAnalyticsModule, UserTrackingService } from '@angular/fire/analytics';


import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuhenticationComponent } from './view/auhentication/auhentication.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AngularMaterialModule} from './angular-material.module';
import { DashboardComponent, DialogModifyDetails, DialogModifyTags } from './view/dashboard/dashboard.component';
import { BlockComponent } from './view/block/block.component';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxBlocklyModule } from 'ngx-blockly';
import { BlocklyComponent } from './view/blockly/blockly.component';
import { HttpClientModule } from '@angular/common/http';
import { AdminComponent } from './view/admin/admin.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AdminusersComponent } from './view/adminusers/adminusers.component';
import { AdmincentroComponent } from './view/admincentro/admincentro.component';
import { AdminprojectsComponent } from './view/adminprojects/adminprojects.component';
import { MenuusuComponent } from './view/menuusu/menuusu.component';
import { UserdetailsComponent } from './view/userdetails/userdetails.component';
import { DataComponent } from './view/data/data.component';
import { MatPaginatorModule, MatSortModule, MatTableModule } from '@angular/material';

//ngxCharts
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DialogShowDevices } from './view/adminusers/adminusers.component';
import { NewprojectComponent } from './view/newproject/newproject.component';

@NgModule({
  declarations: [
    AppComponent,
    AuhenticationComponent,
    DashboardComponent,
    BlockComponent,
    BlocklyComponent,
    AdminComponent,
    AdminusersComponent,
    AdmincentroComponent,
    AdminprojectsComponent,
    MenuusuComponent,
    UserdetailsComponent,
    NewprojectComponent,
    DialogModifyDetails,
    DialogModifyTags,
    DialogShowDevices,
    DataComponent
  ],
  imports: [
    BrowserModule,
    AngularDualListBoxModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    //FlexLayoutModule,
    AngularFireAuthModule,//Declaracion de modulos firebase
    NgxBlocklyModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    NgxChartsModule, 
    BrowserAnimationsModule,
    AngularFireAnalyticsModule
  ],
  entryComponents: [
    DialogModifyDetails,
    DialogModifyTags,
    DialogShowDevices
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, AngularFireAuthGuard, AngularFirestore, UserTrackingService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
