import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from '../../controller/database.service';
import { ParticleService } from 'src/app/controller/particle.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  users;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup
  lastFormGroup: FormGroup
  selectedUser
  source = [];
  target = [];
  filter = [];
  devices
  load = false
  result = '¿Confirmar cambios?'

  format = {
    add: 'Añadir', remove: 'Quitar', all: 'Todos', none: 'Ninguno',
    draggable: true, locale: 'da'
  };

  constructor(private _formBuilder: FormBuilder, public db: DatabaseService, public particle: ParticleService) { }

  ngOnInit() {
    this.db.subscribeToUsers().subscribe(users => {
      this.users = users;
    });

    this.firstFormGroup = this._formBuilder.group({
      userCtrl: ['', Validators.required]
    });
  }

  change() {
    console.log(this.selectedUser)
  }
  clean() {
    this.source = []
    this.target = []
    this.filter = []
  }

  async delay(ms: number) {
    return await new Promise(resolve => setTimeout(resolve, ms));
  }

  loadDevices() {
    this.devices = this.particle.getDevices();
    for (let i = 0; i < this.devices.length; i++) {
      for (let j = 0; j < this.selectedUser.devices.length; j++) {
        if (this.devices[i].id == this.selectedUser.devices[j]) {
          this.target.push(this.devices[i].name)
          break;
        }
      }
      this.source.push(this.devices[i].name)
    }
  }


  devicesList() {
    console.log(this.target)
  }

  setDevices() {
    let device_id = []
    this.result = 'Guardando cambios'
    this.target.map(name => {
      for (let j = 0; j < this.devices.length; j++) {
        if (this.devices[j].name == name) {
          device_id.push(this.devices[j].id)
          break;
        }
      }
    })
    this.db.updateUserDevices(this.selectedUser._id, device_id);
    this.result = 'Cambios guardados';

    // .then(data => (data) ? this.result = 'Cambios guardados' : this.result = 'No se han guardado los cambios')
  }
}
