// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/* */


export const environment = 
{
    production: true,
    firebaseConfig: 
    {
        apiKey: "AIzaSyASQkCFNo6V-aW99Rmt7l2O-sz-zhxoiBY",
        authDomain: "sucre4kidsprod.firebaseapp.com",
        databaseURL: "https://sucre4kidsprod.firebaseio.com",
        projectId: "sucre4kidsprod",
        storageBucket: "sucre4kidsprod.appspot.com",
        messagingSenderId: "508819648129",
        appId: "1:508819648129:web:5de073aba192d4fd38e780",
        measurementId: "G-FSG4ME8TPL"
    }
};

/*
export const environment = 
{
    production: true,
    firebaseConfig: 
    {
        apiKey: "AIzaSyDWVinQF0sKw7AZJmqLOS_w7p1XS8gFZDQ",
        authDomain: "sucre4kidsdev.firebaseapp.com",
        databaseURL: "https://sucre4kidsdev.firebaseio.com",
        projectId: "sucre4kidsdev",
        storageBucket: "sucre4kidsdev.appspot.com",
        messagingSenderId: "261390503686",
        appId: "1:261390503686:web:49ea2a48e4338530795887",
        measurementId: "G-7C0D46718Z"
    }
};


export const environment = 
{
    production: true,
    firebaseConfig: 
    {
        apiKey: "AIzaSyASQkCFNo6V-aW99Rmt7l2O-sz-zhxoiBY",
        authDomain: "sucre4kidsprod.firebaseapp.com",
        databaseURL: "https://sucre4kidsprod.firebaseio.com",
        projectId: "sucre4kidsprod",
        storageBucket: "sucre4kidsprod.appspot.com",
        messagingSenderId: "508819648129",
        appId: "1:508819648129:web:5de073aba192d4fd38e780",
        measurementId: "G-FSG4ME8TPL"
 }
};
*/

/*

export const environment = 
{
    production: true,
    firebaseConfig: 
    {
        apiKey: "AIzaSyA4l4BqUxuG03X03WZX1UGa7aBE-IvlTa0",
        authDomain: "angular-loggin.firebaseapp.com",
        databaseURL: "https://angular-loggin.firebaseio.com",
        projectId: "angular-loggin",
        storageBucket: "angular-loggin.appspot.com",
        messagingSenderId: "926420230576"
    }
};
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
