import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuhenticationComponent } from './view/auhentication/auhentication.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { BlockComponent } from './view/block/block.component'
import { Routes, RouterModule } from '@angular/router';
import { BlocklyComponent } from './view/blockly/blockly.component'
import { AdminComponent } from './view/admin/admin.component'
import { AdminusersComponent } from './view/adminusers/adminusers.component'
import { AdmincentroComponent } from './view/admincentro/admincentro.component'
import { AdminprojectsComponent } from './view/adminprojects/adminprojects.component'
import { MenuusuComponent } from './view/menuusu/menuusu.component'
import { UserdetailsComponent } from './view/userdetails/userdetails.component'
import { AdminGuard } from './guards/admin.guard';
import { DataComponent } from './view/data/data.component';
import { CentroadminGuard } from './guards/centroadmin.guard';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { NewprojectComponent } from './view/newproject/newproject.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);


export const routes: Routes = [
  //{ path: '', pathMatch: 'full', redirectTo: '/dashboard' },
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' }, //temporal
  { path: 'login', component: AuhenticationComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'blockly', component: BlockComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } }, // para testeo
  { path: 'project/:ref', component: BlocklyComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'admin', component: AdminComponent, canActivate: [AngularFireAuthGuard, AdminGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'adminusers', component: AdminusersComponent, canActivate: [AngularFireAuthGuard, AdminGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'newproject', component: NewprojectComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'menu', component: MenuusuComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'data', component: DataComponent,canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  { path: 'admincentro', component: AdmincentroComponent, canActivate: [AngularFireAuthGuard, CentroadminGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'adminprojects', component: AdminprojectsComponent, canActivate: [AngularFireAuthGuard, AdminGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'details', component: UserdetailsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'vincular', component: MenuusuComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forRoot(routes)
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  exports: [RouterModule]
})
export class AppRoutingModule { }
