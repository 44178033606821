import { Component, OnInit, ViewEncapsulation, ViewChild, HostListener, Renderer2, ElementRef, NgZone, AfterViewInit} from '@angular/core';
import { NgxBlocklyConfig, NgxBlocklyGeneratorConfig, NgxBlocklyComponent } from 'ngx-blockly';

import { ActivatedRoute } from '@angular/router'; //obtener parametro de la URL
import { DatabaseService } from '../../controller/database.service'
import { ParticleService } from '../../controller/particle.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnalyticsService } from 'src/app/controller/analytics.service';
import { interval } from 'rxjs';
import { Subscription } from 'rxjs';
import { B } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
var selected;

declare var Blockly: any;

@Component({
    selector: 'app-blockly',
    templateUrl: './blockly.component.html',
    styleUrls: ['./blockly.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class BlocklyComponent implements OnInit {
    @ViewChild(NgxBlocklyComponent, { static: false }) workspace;

    code = 'No hay código aun';
    ref: string;
    devices = [];
    fbDevices;
    filteredDevices = [];
    selectedDevide: string;
    core = false;
    innerHeight: any;
    innerHeightTab: any;
    height: any; // Altura dinámica para el contenido
    isMobile: boolean = false; // Identificar si es móvil
    config: any = { readOnly: false };
    width: boolean;
    hide = false;
    project_name: string;
    project_description: string;
    project_version: string;
    project_centro = null;
    user_centro = null;
    interval: any;
    subiendo: boolean = false;
    msg: any = "Cargando proyecto";
    private = true;
    dataService: any;
    sucreProyecto: string;
    imagenSrc :string;
    f_mod:string;
    xmlProy;
    proyectoActual;
    userDB;
    private subscription: Subscription;
    private lastSavedWorkspace: string;
    isInitialized = false;
    private observer: MutationObserver;
     ResizeObserver: any;
     readOnly: boolean = false;


     @HostListener('window:resize', ['$event'])
     onResize(): void {
       this.calculateHeight(); // Recalcular altura al redimensionar
     }
    
   

  

    constructor
    (
      private databaseService: DatabaseService,
      private route: ActivatedRoute, //Declarar la instancia
      public db: DatabaseService,
      public particle: ParticleService,
      private _snackBar: MatSnackBar,
      private analytics: AnalyticsService,
      private renderer: Renderer2,
      private elementRef: ElementRef,
      private ngZone: NgZone,
      private el: ElementRef,
    ) 
    {


      this.fbDevices = [];
        if (this.db.userDB && this.db.userDB.centro) 
        {
            this.db.getCentroById(this.db.userDB.centro).subscribe(c => 
            {
              
                this.user_centro = c;
                console.log('user_centro', this.user_centro);
                
            })
        }

        this.getData();
        console.log("Blockly.FieldBitmap");

        console.log(Blockly.FieldBitmap);


    }


    
    
    
    
    public generatorConfig: NgxBlocklyGeneratorConfig = 
    {
        dart: true,
        javascript: false,
        lua: false,
        php: false,
        python: false,
        xml: true
    };


    onWorkspaceChange(event: any){
     console.log('workspace change', event.type);

    
      if (event.type != 'var_create' && event.type != 'finished_loading' && event.type != 'create' && event.type != 'move' ) {

      console.log('workspace  2 change', event);
      if (this.workspace && this.workspace.workspace) {
        const workspaceSvg = this.workspace.workspace.getParentSvg();
        if (workspaceSvg) {
          const activeHtmlInput = document.querySelector('input.blocklyHtmlInput');
          if (activeHtmlInput) {
            const blocklyWidgetDiv = this.renderer.selectRootElement('.blocklyWidgetDiv', true);
            if (blocklyWidgetDiv) {
              //console.log('blocklyWidgetDiv', blocklyWidgetDiv);
              this.renderer.setStyle(blocklyWidgetDiv, 'display', 'none');
            }
          }
        }
      }
    }}
  



    onCode(code: string) 
    {
      this.ngZone.run(() => {
        this.code = code;
    });
    }

    async delay(ms: number) 
    {
        console.log('delay');
        return await new Promise(resolve => setTimeout(resolve, ms));
    }


    async getDevicesWithRetry(retries = 5, delay = 1000) {
      for (let i = 0; i < retries; i++) {
        try {
          return await this.particle.getDevicesPromise();
        } catch (err) {
          console.error(`Attempt ${i + 1} failed. Retrying in ${delay}ms...`);
          await new Promise(res => setTimeout(res, delay));
          delay *= 2; // Increase delay for next retry
        }
      }
      throw new Error('All attempts failed');
    }

    getParticleDevices() {
      this.particle.getDevicesPromise().then((devs: []) => {
        this.filteredDevices = [];
  
        let newFilteredDevs = this.filteredDevices.slice(0);
        this.devices = devs;
        //console.log('devices', this.devices);
        if (this.devices.length > 0) {
  
          this.devices.forEach(Particle => {
            
            if (this.fbDevices[0] == Particle.id) {
              newFilteredDevs.push(Particle);
            }
          });

          this.filteredDevices = newFilteredDevs;
  
          this.core = true;
          if (this.filteredDevices.length > 0) {
            //console.log('filteredDevices', this.filteredDevices);
            this.selectedDevide = this.filteredDevices[0].id;
            selected = this.selectedDevide;
          }
        }
      }),
        (err) => console.log(err);
  
    }

    /*
    async getParticleDevices() {
      try {
        const devs: any[] = await this.getDevicesWithRetry();
        this.devices = devs;
    
        if (this.devices.length > 0) {
          this.filteredDevices = this.devices.filter(Particle => this.fbDevices.includes(Particle.id));
    
          if (this.filteredDevices.length > 0) {
            this.selectedDevide = this.filteredDevices[0].id;
            selected = this.selectedDevide; // Ensure 'selected' is defined in your scope
          }
    
          this.core = true;
        }
      } catch (err) {
        console.error('Error getting Particle devices:', err);
      }
    }

    */

    async getData()
    {
      await this.delay(500);
      await this.getFbDevices();
    }

    change() 
    {
        selected = this.selectedDevide;
    }

    getFbDevices() 
    {
        this.db.getCurrentUser().subscribe(user => 
        {
            this.fbDevices = user.devices;
            this.getParticleDevices();
        })
    }

 async flash() {
  const FLASH_ERROR = 'flash_error';
  const FLASH_OK = 'flash_ok';
  const ERROR_MSG = 'Error al actualizar';
  const NOT_CONNECTED_MSG = "Dispositivo no conectado. Recargando dispositivos.";

  const logFlashError = (msg) => {
    this.analytics.logEvent(FLASH_ERROR, {
      device: selected,
      userid: this.db.userDB.email,
      idproj: this.ref,
      centro: this.user_centro.nombre ? this.user_centro.nombre : "",
      msg: msg
    });
  };

  if (this.filteredDevices[0].connected) {
    this.msg = "Subiendo tu programa";
    this.openSnackBar(this.msg, 'OK');
    this.subiendo = true;

    try {
      const data = await this.particle.flashCode(this.code, selected);
      this.msg = data;
      this.openSnackBar(this.msg, 'OK');

      const logEvent = this.msg === ERROR_MSG ? FLASH_ERROR : FLASH_OK;
      this.analytics.logEvent(logEvent, {
        device: selected,
        userid: this.db.userDB.email,
        idproj: this.ref,
        centro: this.user_centro.nombre ? this.user_centro.nombre : "",
        msg: 'Device not reachable'
      });

      if (this.msg === ERROR_MSG) {
        this.getFbDevices();
      }
    } catch (err) {
      console.error('Error subiendo programa: ', err);
      logFlashError('Error in code');
      this.msg = ERROR_MSG;
      this.openSnackBar(this.msg, 'Ok');
      this.getFbDevices();
    } finally {
      // Asegurarse de que 'subiendo' se desactive en cualquier caso
      this.subiendo = false;
    }
  } else {
    this.msg = NOT_CONNECTED_MSG;
    logFlashError('Device not reachable');
    this.openSnackBar(this.msg, 'Ok');
    this.getFbDevices();
  }
}



    hideMenu() 
    {
        console.log('hideMenu');
        if (this.hide) 
        {
            this.config = this.configMovil;
            this.hide = false
        }
        else 
        {
            this.config = 
            { 
                trashcan: true 
            };
            this.hide = true
        }
    }

    /*
    ngOnInit() 
    {
        console.log('ngOnInit');
        this.config = {
            ...this.config,
            theme: Blockly.Themes.Modern  // Aplicar el tema Classic de Blockly
            //renderer: 'thrasos'
        };

        this.ref = this.route.snapshot.paramMap.get('ref');
        this.db.getProyectoById(this.ref).subscribe(proy => 
        {

          if (proy && proy.xml !== "") {
            this.workspace.fromXml(proy.xml);
            this.xmlProy = this.workspace.fromXml(proy.xml);
            this.lastSavedWorkspace = this.workspace.toXml();
          }


          this.msg = "";
          //this.project_centro = proy.centro ? proy.centro : '';
          this.project_centro = proy && proy.centro ? proy.centro : '';
          this.private = this.project_centro === '';
          if (proy && proy.version_sucre) {
            this.sucreProyecto = proy.version_sucre;
          }     


          console.log('id_user', this.db.userDB,proy.id_user );
          if (proy.id_user !== this.db.userDB.id_user) {
            this.config.readOnly = true;
            this.readOnly = true; // Si no, establece readOnly en true
            console.log('readOnly', this.readOnly);
            this.lockAllBlocks()
          }
          this.isInitialized = true;
          this.onResize();

          if (proy && proy.mod_date) {
            this.f_mod = proy.mod_date;
          } 

          this.proyectoActual = proy;
        }, (error: any) => 
        {
          console.error('Ocurrió un error al obtener el proyecto:', error);
        });

        this.onResize();
        this.innerHeight = (window.innerHeight - 50) + "px";
        this.innerHeightTab = (window.innerHeight - 100) + "px";
        this.height = this.innerHeightTab;

        this.width = false;
        this.project_name = localStorage.getItem("name");
        this.project_description = localStorage.getItem("description");

        this.subscription = interval(1000).subscribe(() =>  this.getFbDevices());
       // window.addEventListener('beforeunload', (event) => this.confirmExit(event));


       Blockly.hideChaff = function(opt_allowToolbox) {
        Blockly.Tooltip.hide();
        Blockly.WidgetDiv.hideIfOwner(null, true);
        Blockly.DropDownDiv.hideWithoutAnimation();
        Blockly.ContextMenu.hide();     
        if (!opt_allowToolbox) {
          var workspace = Blockly.getMainWorkspace();
          if (workspace.toolbox_ &&
              workspace.toolbox_.flyout_ &&
              workspace.toolbox_.flyout_.autoClose) {
            workspace.toolbox_.clearSelection();
          }
        }
           
      };

      

      
    }*/

      ngOnInit() {
        this.isMobile = window.innerWidth < 767; // Detectar si es móvil
        this.calculateHeight(); // Calcular altura inicial
        console.log('ngOnInit');
        this.config = {
            ...this.config,
            theme: Blockly.Themes.Modern  // Aplicar el tema Modern de Blockly
            // renderer: 'thrasos'
        };
    
        this.ref = this.route.snapshot.paramMap.get('ref');
        
        // Obtenemos el proyecto por ID
        this.db.getProyectoById(this.ref).subscribe(proy => {
            
            if (proy) {
                if (proy.xml && proy.xml !== "") {
                    this.workspace.fromXml(proy.xml);
                    this.xmlProy = this.workspace.fromXml(proy.xml);
                    this.lastSavedWorkspace = this.workspace.toXml();
                }
    
                this.msg = "";
                this.project_centro = proy.centro || '';
                this.private = this.project_centro === '';
                
                if (proy.version_sucre) {
                    this.sucreProyecto = proy.version_sucre;
                }
    
                console.log('id_user', this.db.userDB, proy.id_user);
                if (proy.id_user !== this.db.userDB.id_user) {
                    this.config.readOnly = true;
                    this.readOnly = true; // Establecer en readOnly si no es el usuario actual
                    console.log('readOnly', this.readOnly);
                }
    
                if (proy.mod_date) {
                    this.f_mod = proy.mod_date;
                }
    
                this.proyectoActual = proy;
            } else {
                // Crear un proyecto vacío si no existe en Firebase
                this.proyectoActual = {
                    id_user: this.db.userDB.id_user,
                    xml: '',
                    centro: this.db.userDB.centro,
                    version_sucre: this.db.userDB.version_sucre
                };
                console.log("Proyecto vacío creado");
            }
    
            this.isInitialized = true;
    
            // Configurar el proyecto según la configuración del dispositivo
            const isMobile = window.innerWidth < 767;
            const configTypes = {
                "Basica": isMobile ? this.configMovilBasica : this.configWebBasica,
                "Avanzada": isMobile ? this.configMovilAvanzado : this.configWebAvanzado,
            };
            this.config = {
                ...(configTypes[this.sucreProyecto] || (isMobile ? this.configMovil : this.configWeb)),
                ...this.config
            };
    
            this.innerHeight = (window.innerHeight - 50) + "px";
            this.innerHeightTab = (window.innerHeight - 100) + "px";
            this.height = this.innerHeightTab;
            this.width = false;
            
            this.project_name = localStorage.getItem("name");
            this.project_description = localStorage.getItem("description");
    
            // Suscripción para obtener dispositivos Firebase periódicamente
            this.subscription = interval(1000).subscribe(() => this.getFbDevices());
    
        }, (error: any) => {
            console.error('Ocurrió un error al obtener el proyecto:', error);
        });
  
        Blockly.hideChaff = function(opt_allowToolbox) {
          Blockly.Tooltip.hide();
          Blockly.WidgetDiv.hideIfOwner(null, true);
          Blockly.DropDownDiv.hideWithoutAnimation();
          Blockly.ContextMenu.hide();
          if (!opt_allowToolbox) {
              var workspace = Blockly.getMainWorkspace();
              if (workspace.toolbox_ && workspace.toolbox_.flyout_ && workspace.toolbox_.flyout_.autoClose) {
                  workspace.toolbox_.clearSelection();
              }
          }
      };
      
    }

    calculateHeight(): void {
      const headerHeight = this.isMobile ? 100 : 100; // Ajustar altura del encabezado según el dispositivo
      this.height = `${window.innerHeight - headerHeight}px`; // Calcular altura dinámica
    }

    lockAllBlocks() {
      console.log('lockAllBlocks');
      // Obtén todos los bloques en el workspace
      let allBlocks = this.workspace.workspace.getAllBlocks(false);
    
      // Itera sobre todos los bloques
      for (let block of allBlocks) {
        // Haz que el bloque sea inamovible
        block.setMovable(false);
        // Haz que el bloque no sea editable
        block.setEditable(false);
      }
    }

    ngOnDestroy() 
    {
      console.log('ngOnDestroy');
      const currentWorkspace = this.workspace.toXml();

      if (currentWorkspace !== this.lastSavedWorkspace && !this.config.readOnly) {
        const confirmExit = confirm('Tienes cambios sin guardar. ¿Quieres guardar antes de salir?');
  
        if (confirmExit) {
          this.updateProject();
        }
      }

      //this.saveChanges();
      this.disposeResources();
      //window.removeEventListener('beforeunload', (event) => this.confirmExit(event));

      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      // Quitar todos los elementos de Blockly del DOM para que no fallen los campos
      let ws = Blockly.getMainWorkspace();
      ws.dispose();

          // Habilitar scroll al salir del componente
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    }

/*
    confirmExit(event: BeforeUnloadEvent) {
      const currentWorkspace = this.workspace.toXml();
      if (currentWorkspace !== this.lastSavedWorkspace) {
          event.preventDefault();
          event.returnValue = 'Tienes cambios sin guardar. ¿Quieres guardar antes de salir2?';
      }
  }



  saveChanges() {
    const currentWorkspace = this.workspace.toXml();
    if (currentWorkspace !== this.lastSavedWorkspace) {
        const confirmExit = confirm('Tienes cambios sin guardar. ¿Quieres guardar antes de salir1?');
        if (confirmExit) {
            this.updateProject();
        }
    }
}
*/
disposeResources() {
  console.log('disposeResources');
  if (this.subscription) {
      this.subscription.unsubscribe();
  }

  // Quitar todos los elementos de Blockly del DOM para que no fallen los campos
  let ws = Blockly.getMainWorkspace();
  ws.dispose();
}
    updateProject()
    {
        console.log('updateProject');
        this.lastSavedWorkspace = this.workspace.toXml();
        this.db.updateProject(this.project_name, this.ref, this.workspace.toXml(), this.project_centro, this.project_description);
        localStorage.removeItem("name");
        localStorage.removeItem("description");
    }

    cargarUltimoProyecto()
    {
        console.log('cargarUltimoProyecto');
        this.ref = this.route.snapshot.paramMap.get('ref');

        this.db.getProyectoById(this.ref).subscribe(proy => 
        {
            this.workspace.fromXml(proy.xml);
        })
    }
    
    toggleVisibility()
    {
        console.log('toggleVisibility');
        this.private = !this.private;
        if (this.private) 
        {
            this.project_centro = '';
            this.openSnackBar('Sin centro, el proyecto es privado', 'OK');
        } 
        else 
        {
            this.project_centro = this.user_centro._id;
            this.openSnackBar('Con centro ' + this.user_centro.nombre + ', el proyecto es público', 'OK');
        }
    }

    openSnackBar(message: string, action: string) 
    {
        console.log('openSnackBar');
        this._snackBar.open(message, action, 
        {
            duration: 2000,
        });
    }
//######################
//VERSIÓN AVANZADA
//######################
//VERSIÓN WEB AVANZADA

public configWebAvanzado: NgxBlocklyConfig = 
{
  toolbox: 
  `<xml id="toolbox" style="display: none">
    <category name="Lógica" colour="60">
    <block type="controls_if"></block>
    <block type="logic_compare"></block>
    <block type="logic_operation"></block>
    <block type="logic_negate"></block>
    <block type="logic_null"></block>
  </category>

  <category name="Control" colour="40">
    <block type="base_delay">
      <value name="DELAY_TIME">
        <block type="math_number">
          <field name="NUM">1000</field>
        </block>
      </value>
    </block>
    <block type="controls_for">
      <value name="FROM">
        <block type="math_number">
          <field name="NUM">1</field>
        </block>
      </value>
      <value name="TO">
        <block type="math_number">
          <field name="NUM">10</field>
        </block>
      </value>
    </block>
    <block type="controls_whileUntil"></block>
  </category>

  <category name="Op. matemáticas" colour="20">
    <block type="math_number"></block>
    <block type="math_arithmetic"></block>
    <block type="base_map">
      <value name="DMAX">
        <block type="math_number">
          <field name="NUM">180</field>
        </block>
      </value>
    </block>
    <block type="base_map_2">
      <value name="Num1">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
      <value name="Num2">
        <block type="math_number">
          <field name="NUM">1024</field>
        </block>
      </value>
      <value name="Num3">
        <block type="math_number">
          <field name="NUM">10</field>
        </block>
      </value>
    </block>
  </category>

  <category name="Texto" colour="0">
    <block type="text"></block>
  </category>

  <category name="Variables" colour="340" custom="VARIABLE">  </category>

  <category name="Funciones" colour="320" custom="PROCEDURE">  </category>
  
  <sep></sep>
  <category name="Sensores" colour="210">
    <block type="sensor_temperatura"></block>
    <block type="sensor_humedad_aire"></block>
    <block type="sensor_distancia"></block>
    <block type="sensor_luz"></block>
    <block type="sensor_humedad_suelo"></block>
    <block type="sensor_ruido"></block>
    <block type="sensor_boton"></block>
    <block type="sensor_angulo"></block>
  </category>

  <category name="Actuadores" colour="140" >
    <block type="actuador_led"></block>
    <block type="actuador_barra_led"></block>
    <block type="actuador_led_variable"></block>
    <block type="actuador_rele"></block>
    <block type="actuador_pantalla_digitos"></block>
    <block type="actuador_zumbador"></block>
    <block type="servo_45"></block>
    <category name="música" colour="140">
        <block type="nota"></block>
        <block type="cumpleanyosFeliz"></block>
    </category>
    <category name="Pantalla OLED"  colour="140">
      <block type="actuador_OLED_mover"></block>
      <block type="actuador_OLED_borrar"></block>
      <block type="actuador_OLED_insertar"></block>
    </category>
  </category>

  <category name="Online" colour="280">
    <block type="particle_publish2"></block>
    <block type="particle_subs2"></block>
    <block type="save_data"></block>
  </category>

  <category name="Games" colour="300">
  <block type="particle_publishGame"></block>
  <block type="particle_publishGamePubSub2"></block>
</category>


  </xml>`,
  scrollbars: true,
  trashcan: true
};
//VERSIÓN MÓVIL AVANZADA
public configMovilAvanzado: NgxBlocklyConfig = 
{
  toolbox: 
  `<xml id="toolbox" style="display: none">
    <category name="Lógica" colour="60">
    <block type="controls_if"></block>
    <block type="logic_compare"></block>
    <block type="logic_operation"></block>
    <block type="logic_negate"></block>
    <block type="logic_null"></block>
  </category>

  <category name="Control" colour="40">
    <block type="base_delay">
      <value name="DELAY_TIME">
        <block type="math_number">
          <field name="NUM">1000</field>
        </block>
      </value>
    </block>
    <block type="controls_for">
      <value name="FROM">
        <block type="math_number">
          <field name="NUM">1</field>
        </block>
      </value>
      <value name="TO">
        <block type="math_number">
          <field name="NUM">10</field>
        </block>
      </value>
    </block>
    <block type="controls_whileUntil"></block>
  </category>

  <category name="Op. matemáticas" colour="20">
    <block type="math_number"></block>
    <block type="math_arithmetic"></block>
    <block type="base_map">
      <value name="DMAX">
        <block type="math_number">
          <field name="NUM">180</field>
        </block>
      </value>
    </block>
    <block type="base_map_2">
      <value name="Num1">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
      <value name="Num2">
        <block type="math_number">
          <field name="NUM">1024</field>
        </block>
      </value>
      <value name="Num3">
        <block type="math_number">
          <field name="NUM">10</field>
        </block>
      </value>
    </block>
  </category>

  <category name="Texto" colour="0">
    <block type="text"></block>
  </category>

  <category name="Variables" colour="340" custom="VARIABLE">  </category>

  <category name="Funciones" colour="320" custom="PROCEDURE">  </category>
  
  <sep></sep>
  <category name="Sensores" colour="210">
    <block type="sensor_temperatura"></block>
    <block type="sensor_humedad_aire"></block>
    <block type="sensor_distancia"></block>
    <block type="sensor_luz"></block>
    <block type="sensor_humedad_suelo"></block>
    <block type="sensor_ruido"></block>
    <block type="sensor_boton"></block>
    <block type="sensor_angulo"></block>
  </category>

  <category name="Actuadores" colour="140" >
    <block type="actuador_led"></block>
    <block type="actuador_barra_led"></block>
    <block type="actuador_led_variable"></block>
    <block type="actuador_rele"></block>
    <block type="actuador_pantalla_digitos"></block>
    <block type="actuador_zumbador"></block>
    <block type="servo_45"></block>
    <category name="música" colour="140">
        <block type="nota"></block>
        <block type="cumpleanyosFeliz"></block>
    </category>
    <category name="Pantalla OLED"  colour="140">
      <block type="actuador_OLED_mover"></block>
      <block type="actuador_OLED_borrar"></block>
      <block type="actuador_OLED_insertar"></block>
    </category>
  </category>

  <category name="Online" colour="280">
    <block type="particle_publish2"></block>
    <block type="particle_subs2"></block>
    <block type="save_data"></block>
  </category>
  
  <category name="Games" colour="300">
  <block type="particle_publishGame"></block>
  <block type="particle_publishGamePubSub2"></block>
</category>

  </xml>`,
  scrollbars: true,
  trashcan: true
};

//##################
//VERSIÓN BÁSICA
//##################
//VERSION WEB BÁSICA 
public configWebBasica: NgxBlocklyConfig = 
{
  toolbox: 
    `<xml id="toolbox" style="display: none">
      <category name="Condicionales" colour="60">
        <block type="controls_if_simple"></block>
        <block type="controls_ifelsev2"></block>
        <block type="controls_ifv2"></block>
        <block type="logic_operation"></block>
        <block type="logic_compare"></block>
        <block type="logic_boolean"></block>
      </category>

      <category name="Bucles" colour="40">
        <block type="controls_repeat2"></block>
        <block type="controls_whileUntil2"></block>
      </category>

      <category name="Control" colour="0">
        <block type="base_delay3"></block>
        <block type="base_stop"></block>
      </category>

          <category name="Contadores" colour="340">
      <block type="contador_iniciar"></block>
      <block type="contador_incrementar"></block>
      <block type="contador_decrementar"></block>
      <block type="contador_valor"></block>
    </category>
    

      <category name="Funciones" colour="320" custom="PROCEDURE">  </category>


  
      <sep></sep>
      <category name="Sensores" colour="210">
        <category name="Temperatura" colour="210">
          <block type="calor"></block>
          <block type="frio"></block>
          <block type="sensor_temperatura2"></block>
        </category>

        <category name="Humedad aire" colour="210">
          <block type="humedad_aire_poca"></block>
          <block type="humedad_aire_demasiada"></block>
          <block type="sensor_humedad_aire2"></block>
        </category>

        <category name="Distancia" colour="210">
          <block type="cerca"></block>
          <block type="lejos"></block>
          <block type="sensor_distancia2"></block>
        </category>

        <category name="Luz" colour="210">
          <block type="luz"></block>
          <block type="noLuz"></block>
        </category>

        <category name="Humedad suelo" colour="210">
            <block type="suelo_seco"></block>
            <block type="suelo_humedo"></block>
            <block type="suelo_agua"></block>
        </category>

        <category name="Ruido" colour="210">
          <block type="ruido"></block>
          <block type="silencio"></block>
        </category>

        <category name="Botón" colour="210">
          <block type="boton_pulsado"></block>
          <block type="boton_NO_pulsado"></block>
        </category>
        <category name="Ángulo rotativo" colour="200">
          <block type="angulo_pequeño"></block>
          <block type="angulo_grande"></block>
        </category>
      </category>

      <category name="Actuadores" colour="140" >
        <category name = "Led simple" colour = "140">
          <block type="led_simple_encendido"></block>
          <block type="led_simple_apagado"></block>
        </category>

        <category name = "Led multicolor" colour = "140">
          <block type="led_color_verde"></block>
          <block type="led_color_azul"></block>
          <block type="led_color_morado"></block>
          <block type="led_color_rojo"></block>
          <block type="led_color_naranja"></block>
          <block type="led_color_amarillo"></block>
          <block type="led_color_blanco"></block>
          <block type="led_color_apagado"></block>
        </category>

        <category name = "Barra led" colour = "140">
          <block type="barra_led_select"></block>
        </category>


        <category name = "Pantalla dígitos" colour = "140">
          <block type="pantalla_digitos_input">
            <value name="pantalla">
            <block type="math_number">
              <field name="NUM">0</field>
            </block>
            </value>
          </block>
        </category>

        <category name = "Pantalla matriz" colour = "140">
          <block type="bitmap_matrix_particle"></block>
        </category>

        <category name = "Zumbador" colour = "140">
          <block type="zumbador_encendido"></block>
          <block type="zumbador_apagado"></block>
        </category>

        <category name = "Servo" colour = "140">
          <block type="servo_45"></block>
        </category>
        
        
        <category name="Música" colour="140">
          <category name="Notas" colour = "140">
            <block type="do"></block>
            <block type="re"></block>
            <block type="mi"></block>
            <block type="fa"></block>
            <block type="sol"></block>
            <block type="la"></block>
            <block type="si"></block>
            <block type="silencio_musica"></block>
          </category>

          <category name="Tiempos" colour = "140">
            <block type="negra"></block>
            <block type="blanca"></block>
            <block type="redonda"></block>
            <block type="corchea"></block>
            <block type="semicorchea"></block>
          </category>
          
          <category name="Melodías" colour="140">
            <block type="cumpleanyosFeliz"></block>
          </category>
        </category> 

        <category name="Coche" colour="140">
            <block type="motor_forward"></block>
            <block type="motor_forward2"></block>
            <block type="motor_backward"></block>
            <block type="motor_backward2"></block>
            <block type="motor_right"></block>
            <block type="motor_left"></block>
            <block type="motor_stop"></block>

          </category>

        

      </category>

      <sep></sep>
      <category name="Comunicación" colour="280">
        <block type="particle_publish3"></block>
        <block type="particle_subs3"></block>
      </category>
              
    </xml>`,
scrollbars: true,
trashcan: true
};
//VERSIÓN MÓVIL BÁSICA

//<category name="Otros" colour="20">
//<block type="math_number"></block>
//</category>
public configMovilBasica: NgxBlocklyConfig = 
{
  toolbox: 
    `<xml id="toolbox" style="display: none">
      <category name="Condicionales" colour="60">
        <block type="controls_if_simple"></block>
        <block type="controls_ifelsev2"></block>
        <block type="controls_ifv2"></block>
        <block type="logic_operation"></block>
        <block type="logic_compare"></block>
        <block type="logic_boolean"></block>
      </category>

      <category name="Bucles" colour="40">
        <block type="controls_repeat2"></block>
        <block type="controls_whileUntil2"></block>
      </category>

      <category name="Control" colour="0">
        <block type="base_delay3"></block>
        <block type="base_stop"></block>
      </category>

      <category name="Contadores" colour="340">
        <block type="contador_iniciar"></block>
        <block type="contador_incrementar"></block>
        <block type="contador_decrementar"></block>
        <block type="contador_valor"></block>
      </category>
    

      <category name="Funciones" colour="320" custom="PROCEDURE">  </category>



      <sep></sep>
      <category name="Sensores" colour="210">
      <category name="Temperatura" colour="210">
          <block type="calor"></block>
          <block type="frio"></block>
          <block type="sensor_temperatura2"></block>
        </category>

        <category name="Humedad aire" colour="210">
          <block type="humedad_aire_poca"></block>
          <block type="humedad_aire_demasiada"></block>
          <block type="sensor_humedad_aire2"></block>
        </category>

        <category name="Distancia" colour="210">
          <block type="cerca"></block>
          <block type="lejos"></block>
          <block type="sensor_distancia2"></block>
        </category>

        <category name="Luz" colour="210">
          <block type="luz"></block>
          <block type="noLuz"></block>
        </category>

        <category name="Humedad suelo" colour="210">
            <block type="suelo_seco"></block>
            <block type="suelo_humedo"></block>
            <block type="suelo_agua"></block>
        </category>

        <category name="Ruido" colour="210">
          <block type="ruido"></block>
          <block type="silencio"></block>
        </category>

        <category name="Botón" colour="210">
          <block type="boton_pulsado"></block>
          <block type="boton_NO_pulsado"></block>
        </category>
        <category name="Ángulo rotativo" colour="200">
          <block type="angulo_pequeño"></block>
          <block type="angulo_grande"></block>
        </category>
      </category>

      <category name="Actuadores" colour="140" >
        <category name = "Led simple" colour = "140">
          <block type="led_simple_encendido"></block>
          <block type="led_simple_apagado"></block>
        </category>

        <category name = "Led multicolor" colour = "140">
          <block type="led_color_verde"></block>
          <block type="led_color_azul"></block>
          <block type="led_color_morado"></block>
          <block type="led_color_rojo"></block>
          <block type="led_color_naranja"></block>
          <block type="led_color_amarillo"></block>
          <block type="led_color_blanco"></block>
          <block type="led_color_apagado"></block>
        </category>

        <category name = "Barra led" colour = "140">
          <block type="barra_led_select"></block>
        </category>


        <category name = "Pantalla dígitos" colour = "140">
          <block type="pantalla_digitos_input">
            <value name="pantalla">
            <block type="math_number">
              <field name="NUM">0</field>
            </block>
            </value>
          </block>
        </category>

        <category name = "Pantalla matriz" colour = "140">
          <block type="bitmap_matrix_particle"></block>
        </category>

        <category name = "Zumbador" colour = "140">
          <block type="zumbador_encendido"></block>
          <block type="zumbador_apagado"></block>
        </category>

        <category name = "Servo" colour = "140">
          <block type="servo_45"></block>
        </category>
        
        <category name="Música" colour="140">
          <category name="Notas" colour = "140">
            <block type="do"></block>
            <block type="re"></block>
            <block type="mi"></block>
            <block type="fa"></block>
            <block type="sol"></block>
            <block type="la"></block>
            <block type="si"></block>
            <block type="silencio_musica"></block>
          </category>

          <category name="Tiempos" colour = "140">
            <block type="negra"></block>
            <block type="blanca"></block>
            <block type="redonda"></block>
            <block type="corchea"></block>
            <block type="semicorchea"></block>
          </category>
          
          <category name="Melodías" colour="140">
            <block type="cumpleanyosFeliz"></block>
          </category>
        </category> 

        <category name="Coche" colour="140">
        <block type="motor_forward"></block>
        <block type="motor_forward2"></block>
        <block type="motor_backward"></block>
        <block type="motor_backward2"></block>
        <block type="motor_right"></block>
        <block type="motor_left"></block>
        <block type="motor_stop"></block>
      </category>
      </category>

      <sep></sep>
      <category name="Comunicación" colour="280">
        <block type="particle_publish3"></block>
        <block type="particle_subs3"></block>
      </category>

     
              
    </xml>`,
scrollbars: true,
trashcan: true
};


//###########
//SIN VERSIÓN
//###########
//CONFIG WEB
  public configWeb: NgxBlocklyConfig = 
  {
    toolbox: 
    `<xml id="toolbox" style="display: none">
      <category name="Lógica" colour="60">
      <block type="controls_if"></block>
      <block type="logic_compare"></block>
      <block type="logic_operation"></block>
      <block type="logic_negate"></block>
      <block type="logic_null"></block>
    </category>

    <category name="Control" colour="40">
      <block type="base_delay">
        <value name="DELAY_TIME">
          <block type="math_number">
            <field name="NUM">1000</field>
          </block>
        </value>
      </block>
      <block type="controls_for">
        <value name="FROM">
          <block type="math_number">
            <field name="NUM">1</field>
          </block>
        </value>
        <value name="TO">
          <block type="math_number">
            <field name="NUM">10</field>
          </block>
        </value>
      </block>
      <block type="controls_whileUntil"></block>
    </category>

    <category name="Op. matemáticas" colour="20">
      <block type="math_number"></block>
      <block type="math_arithmetic"></block>
      <block type="base_map">
        <value name="DMAX">
          <block type="math_number">
            <field name="NUM">180</field>
          </block>
        </value>
      </block>
      <block type="base_map_2">
        <value name="Num1">
          <block type="math_number">
            <field name="NUM">0</field>
          </block>
        </value>
        <value name="Num2">
          <block type="math_number">
            <field name="NUM">1024</field>
          </block>
        </value>
        <value name="Num3">
          <block type="math_number">
            <field name="NUM">10</field>
          </block>
        </value>
      </block>
    </category>

    <category name="Texto" colour="0">
      <block type="text"></block>
    </category>

    <category name="Variables" colour="340" custom="VARIABLE">  </category>

        <category name="Contadores" colour="340">
      <block type="contador_iniciar"></block>
      <block type="contador_incrementar"></block>
      <block type="contador_decrementar"></block>
      <block type="contador_valor"></block>
    </category>
    

    <category name="Funciones" colour="320" custom="PROCEDURE">  </category>


    <sep></sep>
    <category name="Sensores" colour="210">
      <category name="Sucre4STEM" colour="210">
        <block type="sensor_temperatura"></block>
        <block type="sensor_humedad_aire"></block>
        <block type="sensor_distancia"></block>
        <block type="sensor_luz"></block>
        <block type="sensor_humedad_suelo"></block>
        <block type="sensor_ruido"></block>
        <block type="sensor_boton"></block>
        <block type="sensor_angulo"></block>
      </category>

      <category name="Sucre4KIDS" colour="210">
        <category name="Temperatura" colour="210">
          <block type="calor"></block>
          <block type="frio"></block>
        </category>

        <category name="Distancia" colour="210">
          <block type="cerca"></block>
          <block type="lejos"></block>
        </category>

        <category name="Luz" colour="210">
          <block type="luz"></block>
          <block type="noLuz"></block>
        </category>

        <category name="Ruido" colour="210">
          <block type="ruido"></block>
          <block type="silencio"></block>
        </category>

        <category name="Botón" colour="210">
          <block type="boton_pulsado"></block>
          <block type="boton_NO_pulsado"></block>
        </category>
        <category name="Ángulo rotativo" colour="200">
          <block type="angulo_pequeño"></block>
          <block type="angulo_grande"></block>
        </category>
        <category name="Humedad suelo" colour="210">
            <block type="suelo_humedo"></block>
            <block type="suelo_seco"></block>
            <block type="suelo_agua"></block>
          </category>
          <category name="Humedad aire" colour="210">
            <block type="humedad_aire_poca"></block>
            <block type="humedad_aire_optima"></block>
            <block type="humedad_aire_demasiada"></block>
          </category>
      </category>
    </category>

    <category name="Actuadores" colour="140" >
      <category name = "Sucre4STEM" colour="140">
        <block type="actuador_led"></block>
        <block type="actuador_barra_led"></block>
        <block type="actuador_led_variable"></block>
        <block type="actuador_rele"></block>
        <block type="actuador_pantalla_digitos"></block>
        <block type="actuador_zumbador"></block>

        <category name="música" colour="140">
            <block type="nota"></block>
        </category>
        <category name="Pantalla OLED"  colour="140">
          <block type="actuador_OLED_mover"></block>
          <block type="actuador_OLED_borrar"></block>
          <block type="actuador_OLED_insertar"></block>
        </category>
      </category>

      <category name= "Sucre4KIDS" colour = "140">
        <category name = "Led simple" colour = "140">
          <block type="led_simple_encendido"></block>
          <block type="led_simple_apagado"></block>
        </category>

        <category name = "Led multicolor" colour = "140">
          <block type="led_color_verde"></block>
          <block type="led_color_azul"></block>
          <block type="led_color_morado"></block>
          <block type="led_color_rojo"></block>
          <block type="led_color_naranja"></block>
          <block type="led_color_amarillo"></block>
          <block type="led_color_blanco"></block>
          <block type="led_color_apagado"></block>
        </category>

        <category name = "Zumbador" colour = "140">
          <block type="zumbador_encendido"></block>
          <block type="zumbador_apagado"></block>
        </category>

        <category name = "Barra led" colour = "140">
          <block type="barra_led_select"></block>
        </category>

        <category name = "Pantalla dígitos" colour = "140">
          <block type="pantalla_digitos_input"></block>
        </category>
          
        <category name="música" colour="140">
          <category name="Notas" colour = "140">
            <block type="do"></block>
            <block type="re"></block>
            <block type="mi"></block>
            <block type="fa"></block>
            <block type="sol"></block>
            <block type="la"></block>
            <block type="si"></block>
            <block type="silencio_musica"></block>
          </category>

          <category name="Tiempos" colour = "140">
            <block type="negra"></block>
            <block type="blanca"></block>
            <block type="redonda"></block>
            <block type="corchea"></block>
            <block type="semicorchea"></block>
          </category>
          <category name="Melodías" colour="140">
              <block type="cumpleanyosFeliz"></block>
          </category>
        </category>
      </category>
    </category>

 
                
    </xml>`,
    scrollbars: true,
    trashcan: true
  };
//CONFIG MOVIL
  public configMovil: NgxBlocklyConfig = 
  {
    toolbox: 
    `<xml id="toolbox" style="display: none">
      <category name="Lógica" colour="60">
      <block type="controls_if"></block>
      <block type="logic_compare"></block>
      <block type="logic_operation"></block>
      <block type="logic_negate"></block>
      <block type="logic_null"></block>
    </category>

    <category name="Control" colour="40">
      <block type="base_delay">
        <value name="DELAY_TIME">
          <block type="math_number">
            <field name="NUM">1000</field>
          </block>
        </value>
      </block>
      <block type="controls_for">
        <value name="FROM">
          <block type="math_number">
            <field name="NUM">1</field>
          </block>
        </value>
        <value name="TO">
          <block type="math_number">
            <field name="NUM">10</field>
          </block>
        </value>
      </block>
      <block type="controls_whileUntil"></block>
    </category>

    <category name="Op. matemáticas" colour="20">
      <block type="math_number"></block>
      <block type="math_arithmetic"></block>
      <block type="base_map">
        <value name="DMAX">
          <block type="math_number">
            <field name="NUM">180</field>
          </block>
        </value>
      </block>
      <block type="base_map_2">
        <value name="Num1">
          <block type="math_number">
            <field name="NUM">0</field>
          </block>
        </value>
        <value name="Num2">
          <block type="math_number">
            <field name="NUM">1024</field>
          </block>
        </value>
        <value name="Num3">
          <block type="math_number">
            <field name="NUM">10</field>
          </block>
        </value>
      </block>
    </category>

    <category name="Texto" colour="0">
      <block type="text"></block>
    </category>

    <category name="Variables" colour="340" custom="VARIABLE">  </category>

    
    <category name="Contadores" colour="340">
      <block type="contador_iniciar"></block>
      <block type="contador_incrementar"></block>
      <block type="contador_decrementar"></block>
      <block type="contador_valor"></block>
    </category>
    

    <category name="Funciones" colour="320" custom="PROCEDURE">  </category>

    <sep></sep>
    <category name="Sensores" colour="210">
      <category name="Sucre4STEM" colour="210">
        <block type="sensor_temperatura"></block>
        <block type="sensor_humedad_aire"></block>
        <block type="sensor_distancia"></block>
        <block type="sensor_luz"></block>
        <block type="sensor_humedad_suelo"></block>
        <block type="sensor_ruido"></block>
        <block type="sensor_boton"></block>
        <block type="sensor_angulo"></block>
      </category>

      <category name="Sucre4KIDS" colour="210">
        <category name="Temperatura" colour="210">
          <block type="calor"></block>
          <block type="frio"></block>
        </category>

        <category name="Distancia" colour="210">
          <block type="cerca"></block>
          <block type="lejos"></block>
        </category>

        <category name="Luz" colour="210">
          <block type="luz"></block>
          <block type="noLuz"></block>
        </category>

        <category name="Ruido" colour="210">
          <block type="ruido"></block>
          <block type="silencio"></block>
        </category>

        <category name="Botón" colour="210">
          <block type="boton_pulsado"></block>
          <block type="boton_NO_pulsado"></block>
        </category>
        <category name="Ángulo rotativo" colour="200">
          <block type="angulo_pequeño"></block>
          <block type="angulo_grande"></block>
        </category>
        <category name="Humedad suelo" colour="210">
            <block type="suelo_humedo"></block>
            <block type="suelo_seco"></block>
            <block type="suelo_agua"></block>
          </category>
          <category name="Humedad aire" colour="210">
            <block type="humedad_aire_poca"></block>
            <block type="humedad_aire_optima"></block>
            <block type="humedad_aire_demasiada"></block>
          </category>
      </category>
    </category>

    <category name="Actuadores" colour="140" >
      <category name = "Sucre4STEM" colour="140">
        <block type="actuador_led"></block>
        <block type="actuador_barra_led"></block>
        <block type="actuador_led_variable"></block>
        <block type="actuador_rele"></block>
        <block type="actuador_pantalla_digitos"></block>
        <block type="actuador_zumbador"></block>

        <category name="música" colour="140">
            <block type="nota"></block>
        </category>
        <category name="Pantalla OLED"  colour="140">
          <block type="actuador_OLED_mover"></block>
          <block type="actuador_OLED_borrar"></block>
          <block type="actuador_OLED_insertar"></block>
        </category>
      </category>

      <category name= "Sucre4KIDS" colour = "140">
        <category name = "Led simple" colour = "140">
          <block type="led_simple_encendido"></block>
          <block type="led_simple_apagado"></block>
        </category>

        <category name = "Led multicolor" colour = "140">
          <block type="led_color_verde"></block>
          <block type="led_color_azul"></block>
          <block type="led_color_morado"></block>
          <block type="led_color_rojo"></block>
          <block type="led_color_naranja"></block>
          <block type="led_color_amarillo"></block>
          <block type="led_color_blanco"></block>
          <block type="led_color_apagado"></block>
        </category>

        <category name = "Zumbador" colour = "140">
          <block type="zumbador_encendido"></block>
          <block type="zumbador_apagado"></block>
        </category>

        <category name = "Barra led" colour = "140">
          <block type="barra_led_select"></block>
        </category>

        <category name = "Pantalla dígitos" colour = "140">
          <block type="pantalla_digitos_input"></block>
        </category>
          
        <category name="música" colour="140">
          <category name="Notas" colour = "140">
            <block type="do"></block>
            <block type="re"></block>
            <block type="mi"></block>
            <block type="fa"></block>
            <block type="sol"></block>
            <block type="la"></block>
            <block type="si"></block>
            <block type="silencio_musica"></block>
          </category>

          <category name="Tiempos" colour = "140">
            <block type="negra"></block>
            <block type="blanca"></block>
            <block type="redonda"></block>
            <block type="corchea"></block>
            <block type="semicorchea"></block>
          </category>
          <category name="Melodías" colour="140">
              <block type="cumpleanyosFeliz"></block>
          </category>
        </category>
      </category>
    </category>

    <category name="Online" colour="280">
      <block type="particle_publish2"></block>
      <block type="particle_subs2"></block>
      <block type="save_data"></block>
    </category>

    <category name="Games" colour="300">
      <block type="particle_publishGame"></block>
      <block type="particle_publishGamePubSub2"></block>
    </category>
                
    </xml>`,
    scrollbars: true,
    trashcan: true
  };

}
