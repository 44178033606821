import { Injectable } from '@angular/core';
import { AngularFireAnalytics, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable
({
    providedIn: 'root'
})
export class AnalyticsService 
{

    constructor(private analytics: AngularFireAnalytics, private afAuth: AngularFireAuth) 
    {
        this.afAuth.auth.onAuthStateChanged(user => 
        {
            if (user) 
            {
                // User is signed in.
                this.analytics.setUserProperties({ email: user.email })

            }
        });
    }

    logEvent(eventName, params) 
    {
        this.analytics.logEvent(eventName, params);
    }
}
