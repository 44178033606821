import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DatabaseService } from '../controller/database.service'


@Injectable({
  providedIn: 'root'
})
export class CentroadminGuard implements CanActivate {
  constructor(public db: DatabaseService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {

      let result = false;
      this.db.subscribeToCentros().subscribe(centros => {
        centros.forEach(c => {
          if (c.id_user_admin === this.db.user.uid) {
            result = true;
            return;
          }
        });
        if (!result) {
          this.router.navigate(['/dashboard']);
        }
        resolve(result);
      });
    });
  }

}
