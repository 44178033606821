import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/controller/database.service'
import { ParticleService } from 'src/app/controller/particle.service';
import { AnalyticsService } from 'src/app/controller/analytics.service';

// to be able to create users withouth loggin in
import { environment } from '../../../environments/environment';   // <-- Your project's configuration here.
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-admincentro',
  templateUrl: './admincentro.component.html',
  styleUrls: ['./admincentro.component.css']
})
export class AdmincentroComponent implements OnInit, OnDestroy {
  user;
  usersCentro;
  centro;
  deletingUser;
  deleting = false;
  allDevices;
  filteredDevices = [];
  devicesHash = {};
  selDevice;

  msgCreated = false;

  // nuevo registro usuario
  regnombre: string;
  regemail: string;
  regpassword: string;
  regpasswordrep: string;
  regDevice: string;
  errorReg: boolean = false;
  msg_error_reg = '';

  // para crear usuarios sin loguearlos
  tempApp = null;

  // etiquetas del centro
  regtag: string;


  constructor(public db: DatabaseService,
    private analytics: AnalyticsService,
    public particle: ParticleService) {
    this.tempApp = firebase.initializeApp(environment.firebaseConfig, "tempApp");
  }

  ngOnInit() {
    this.db.getCurrentUser().subscribe(uDB => {
      this.user = uDB;
      this.loadDevices();
      this.db.getCentroById(uDB.centro).subscribe(c => {
        this.centro = c;
      });
      // Filtrar los usuarios de este centro
      this.db.subscribeToUsers().subscribe(users => {
        this.usersCentro = [];
        users.forEach(u => {
          if (u.centro === uDB.centro) {
            this.usersCentro.push(u);
          }
        });
      });
    });

  }

  ngOnDestroy() {
    this.tempApp.delete();
  }

  deleteUser(num) {
    this.deletingUser = this.usersCentro[num];
    this.deleting = true;
  }

  confirmDeleteUser() {
    this.db.deleteUser(this.deletingUser._id);
    this.analytics.logEvent('admin_centro_delete_user', { deletedid: this.deletingUser._id, userid: this.db.userDB.email, deletedemail: this.deletingUser.email, centro: this.centro.name });
    this.deleting = false;
  }

  createNewUserInCentro() {
    this.errorReg = null;
    if (this.regemail !== '') {
      if (this.regpassword === this.regpasswordrep) {

        return this.tempApp.auth().createUserWithEmailAndPassword(this.regemail, this.regpassword)
          .then(newUser => {
            console.log("User " + newUser.user.uid + " created successfully!");
            this.analytics.logEvent('admin_centro_new_user', { newid: newUser.user.uid, userid: this.db.userDB.email, newemail: this.regemail, centro: this.centro.name });
            let disp = [];
            disp.push(this.regDevice);
            this.db.createNewUserWithId(newUser.user.uid, this.regemail, this.regnombre, this.centro._id, disp);
            this.tempApp.auth().signOut();
            this.msgCreated = true;

          }).catch((error) => {
            console.log('Error fetching user data:', error);
            this.msg_error_reg = error.message;
            this.errorReg = true;
          });

      } else {
        this.msg_error_reg = 'Las contraseñas no coinciden';
        this.errorReg = true;
      }
    } else {
      this.msg_error_reg = 'Escribe un email';
      this.errorReg = true;
    }
  }

  async delay(ms: number) {
    return await new Promise(resolve => setTimeout(resolve, ms));
  }

  loadDevices() {
    this.allDevices = this.particle.getDevices();
    this.filterDevices();
  }

  filterDevices() {
    for (let i = 0; i < this.allDevices.length; i++) {
      if (this.user.devices.includes(this.allDevices[i].id)) {
        this.devicesHash[this.allDevices[i].id] = this.allDevices[i].name;
        this.filteredDevices.push(this.allDevices[i]);
      }
    }
  }

  onChangeDevice(u) {
    this.db.updateUserDevices(u._id, u.devices);
  }


  add(): void {
    if (this.regtag) {
      if (!this.centro.tags) {
        this.centro.tags = [];
      }
      this.centro.tags.push(this.regtag);
      this.db.updateCentroTags(this.centro._id, this.centro.tags);
    }
    this.regtag = null;
  }

  remove(tag: string): void {
    const index = this.centro.tags.indexOf(tag);

    if (index >= 0) {
      this.centro.tags.splice(index, 1);
      this.db.updateCentroTags(this.centro._id, this.centro.tags);
    }
  }

}
