import { Component, Inject, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/controller/database.service'

// to be able to create users withouth loggin in
import { environment } from '../../../environments/environment';   // <-- Your project's configuration here.
import * as firebase from 'firebase/app';

import { ParticleService } from 'src/app/controller/particle.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dashboard/dashboard.component';


@Component({
  selector: 'app-adminusers',
  templateUrl: './adminusers.component.html',
  styleUrls: ['./adminusers.component.css']
})
export class AdminusersComponent implements OnInit {
  users;
  admins;
  centros;
  deletingUser;
  deleting = false;
  deletingCentro;
  deletingC = false;
  creating = false;

  nombreCentro = '';

  // nuevo registro usuario
  regnombre: string;
  regemail: string;
  regpassword: string;
  regpasswordrep: string;
  errorReg: boolean = false;
  msg_error_reg = '';

  tempApp = null;

  devices;
  userDevices = [];
  devicesHash = {};



  constructor(public db: DatabaseService, public particle: ParticleService, public dialog: MatDialog) {
    this.tempApp = firebase.initializeApp(environment.firebaseConfig, "tempApp2");
  }

  ngOnInit() {
    this.db.subscribeToUsers().subscribe(users => {
      this.users = users;
      this.db.subscribeToAdmins().subscribe(admins => {
        this.db.subscribeToCentros().subscribe(c => {
          this.centros = c;
          this.admins = admins.user_id;
          this.users.forEach(u => {
            if (admins.user_id.includes(u._id)) {
              u.admin = true;
            } else {
              u.admin = false;
            }
            u.centroadmin = false;
            this.centros.forEach(c => {
              if (c.id_user_admin === u._id) {
                u.centroadmin = true;
              }
            });
          });
        });
      });
    });

    this.devices = this.particle.getDevices();

    for (let i = 0; i < this.devices.length; i++) {
      this.devicesHash[this.devices[i].id] = this.devices[i].name;
    }

  }

  ngOnDestroy() {
    this.tempApp.delete();
  }

  deleteUser(num) {
    this.deletingUser = this.users[num];
    this.deleting = true;
  }

  confirmDeleteUser() {
    this.db.deleteUser(this.deletingUser._id);
    this.deleting = false;
  }

  deleteCentro(num) {
    this.deletingCentro = this.centros[num];
    this.deletingC = true;
  }

  confirmDeleteCentro() {
    this.db.deleteCentro(this.deletingCentro._id);
    this.deletingC = false;
  }

  crearCentro() {
    this.errorReg = false;
    if (this.nombreCentro !== '') {
      if (this.regemail !== '') {
        if (this.regpassword === this.regpasswordrep) {

          // Crear centro
          let centro_id = this.db.createNewCentro(this.nombreCentro, this.regemail);

          // Crear usuario admin del centro
          this.tempApp.auth().createUserWithEmailAndPassword(this.regemail, this.regpassword)
            .then(newUser => {
              console.log("User " + newUser.user.uid + " created successfully!");
              let admin_id = newUser.user.uid;
              this.db.createNewUserWithId(admin_id, this.regemail, this.regnombre, centro_id, []);
              this.tempApp.auth().signOut();

              // Asignar al centro el usuario admin
              this.db.updateCentroAdmin(centro_id, admin_id);

              this.creating = true;
              this.nombreCentro = '';
              this.regnombre = '';
              this.regemail = '';
              this.regpassword = '';
              this.regpasswordrep = '';

            }).catch((error) => {
              console.log('Error fetching user data:', error);
              this.msg_error_reg = error.message;
              this.errorReg = true;
            });

        } else {
          this.msg_error_reg = 'Las contraseñas no coinciden';
          this.errorReg = true;
        }
      } else {
        this.msg_error_reg = 'Escribe un email';
        this.errorReg = true;
      }
    } else {
      this.msg_error_reg = 'Escribe el nombre del centro';
      this.errorReg = true;
    }
  }

  changeAdmin(u) {
    if (u.admin) {
      this.admins.push(u._id);
    } else {
      const index = this.admins.indexOf(u._id);
      if (index > -1) {
        this.admins.splice(index, 1);
      }
    }
    this.db.updateAdmins(this.admins);
  }

  changeCentroAdmin(u) {
    if (u.centroadmin) {
      // Añadir id usuario en id admin centro
      console.log('AÑADIR ID EN CENTRO:', u.centro, ' USUARIO ', u._id);
      this.db.updateCentroAdmin(u.centro, u._id);
    } else {
      this.db.updateCentroAdmin(u.centro, '');
    }
  }

  updateCentro(u) {
    console.log('updating ' + u._id + ' centro ' + u.centro);
    this.db.updateUserCentro(u._id, u.centro);
  }

  async delay(ms: number) {
    return await new Promise(resolve => setTimeout(resolve, ms));
  }

  loadUserDevices(user) {
    this.userDevices = [];
    user.devices.forEach(d => {
      this.userDevices.push({ id: d, name: this.devicesHash[d] });
    });

    if (this.userDevices.length > 0) {
      this.dialog.open(DialogShowDevices, {
        data: { uDev: this.userDevices },
      });
    }
  }

  sortProjectsBy(prop) {
    this.users.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1)
  }

}


@Component({
  selector: 'dialog-show-devices',
  templateUrl: 'dialog-show-devices.html',
})
export class DialogShowDevices {
  constructor(
    public dialogRef: MatDialogRef<DialogShowDevices>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }
}