import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Usuario, Proyecto, Admins, Centro} from '../model/firestore-model';
import { switchMap } from 'rxjs/operators';
import { AngularFireAuth } from "@angular/fire/auth";
import { promise } from 'protractor';
import { resolve } from 'url';
import { map } from 'rxjs/operators';
//import { Variables } from 'blockly';

require("firebase/functions");

const colUsu = 'usuarios';
const colProy = 'proyectos';
const colAdmin = 'admins';
const colCentro = 'centros';
const docAdmin = 'administradores_id';
const colLect = 'lectures';
//const colMontajes = 'montajes';

@Injectable(
{
    providedIn: 'root'
})

export class DatabaseService 
{
    user: firebase.User;
    userDB = null;
    userFilter$: BehaviorSubject<string | null>;
    centroFilter$: BehaviorSubject<string | null>;
    tipoSucre: string;

    constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) 
    {
        this.afAuth.auth.onAuthStateChanged(user => 
        {
            if (user) 
            {
                // User is signed in.
                this.user = user;
                this.getCurrentUser().subscribe(u => 
                {
                    this.userDB = u;
                    console.log('got db user ' , this.userDB)
                    if(!this.userDB.version_sucre)
                    {
                        this.userDB.version_sucre = 'Avanzada';
                    }
                })
            } 
            else 
            {
                // No user is signed in.
                this.userDB = null;
            }
        });
        this.userFilter$ = new BehaviorSubject(null);
        this.centroFilter$ = new BehaviorSubject(null);
    }

    subscribeToProyectos(): Observable<Proyecto[]> 
    {
        return this.db
        .collection<Proyecto>(colProy)
        .valueChanges({ idField: '_id' });
    }


    subscribeToUsers(): Observable<Usuario[]>
    {
        return this.db
        .collection<Usuario>(colUsu)
        .valueChanges({ idField: '_id' });
    }

    subscribeToAdmins(): Observable<Admins>
    {
        return this.db
        .collection(colAdmin)
        .doc<any>(docAdmin)
        .valueChanges();
    }

    updateAdmins(ids) 
    {
        this.db.collection(colAdmin)
        .doc(docAdmin)
        .update({ user_id: ids });
    }

    subscribeToCentros(): Observable<Centro[]> 
    {
        return this.db
        .collection<Centro>(colCentro)
        .valueChanges({ idField: '_id' });
    }

    getUsuarioById(userId: string): Observable<Usuario> 
    {
        return this.db
        .collection(colUsu)
        .doc<Usuario>(userId)
        .valueChanges();
    }

    deleteUser(reference) 
    {
        this.db.collection(colUsu).doc(reference).delete().then(() => 
        {
            console.log("Document successfully deleted!");
            this.afAuth.auth
        }).catch((error) => 
        {
          console.error("Error removing document: ", error);
        });
    }

    getCurrentUser(): Observable<Usuario> 
    {
        if (this.user) 
        {
            return this.db
                .collection(colUsu)
                .doc<Usuario>(this.user.uid)
                .valueChanges();
        }
    }

    getCurrentSucreUser(): Observable<string> 
    {
        if (this.user) 
        {
          return this.db
            .collection<Usuario>(colUsu)
            .doc<Usuario>(this.user.uid)
            .valueChanges()
            .pipe
            (
              map((usuario: Usuario) => usuario.version_sucre)
            );
        }
    }

    getSucreUser(): Observable<string> 
    {
        return this.getCurrentSucreUser();
    }
    

    getProyectoById(proyId: string): Observable<Proyecto> 
    {
        return this.db
            .collection(colProy)
            .doc<Proyecto>(proyId)
            .valueChanges();
    }

    getVersionSucreByIdUser(idUsuario: string): Observable<string> 
    {
        return new Observable<string | undefined>((observer) => 
        {
          const subscription = this.db
            .collection<Proyecto>(colProy)
            .doc(idUsuario)
            .valueChanges()
            .subscribe((usuario: Usuario | undefined) => 
            {
              if (usuario) 
              {
                observer.next(usuario.version_sucre);
              } 
              else 
              {
                observer.next(undefined);
              }
              observer.complete();
            });
      
          return () => subscription.unsubscribe();
        });
    }

    getVersionSucreById(idProyecto: string): Observable<string> 
    {
        return new Observable<string | undefined>((observer) => 
        {
          const subscription = this.db
            .collection<Proyecto>(colProy)
            .doc(idProyecto)
            .valueChanges()
            .subscribe((proyecto: Proyecto | undefined) => 
            {
              if (proyecto) 
              {
                observer.next(proyecto.version_sucre);
              } 
              else 
              {
                observer.next(undefined);
              }
              observer.complete();
            });
      
          return () => subscription.unsubscribe();
        });
    }

    getCentroById(centroId: string): Observable<Centro> 
    {
        return this.db
            .collection(colCentro)
            .doc<Centro>(centroId)
            .valueChanges();
    }

    getVariables(scName: string): Array<any>
    {
        var variables = [];
        firebase.firestore().collection(colLect).limit(1).get().then(querySnapshot =>
        {
            querySnapshot.docs[0].ref.collection(scName).limit(1).get().then(querySnapshot =>
            {
                querySnapshot.forEach((doc) => 
                {
                    Array.prototype.push.apply(variables,(doc.get("subcolid")));
                });
            });
        });
        return variables;
    }

    getLecturasValues(scName: string, varName: string): Array<any>
    {
        var lectures = [{name: varName, series: []}];
        var lecturesValores = [];
        firebase.firestore().collection(colLect).limit(1).get().then(querySnapshot =>
        {
            querySnapshot.docs[0].ref.collection(scName).limit(1).get().then(querySnapshot =>
            {
                querySnapshot.docs[0].ref.collection(varName).orderBy("timestamp").onSnapshot((querySnapshot) => 
                {
                    querySnapshot.forEach((doc) => 
                    {
                        var dateLecture = new Date(doc.get("timestamp").seconds*1000);
                        var fechaString = dateLecture.toLocaleString();
                        //var horaString = dateLecture.toLocaleTimeString();
                        //lectures.unshift({"valor": doc.get("valor"), "fecha": fechaString, "hora": horaString});
                        lecturesValores.unshift({value: doc.get("valor"), name: dateLecture});
                    });
                });
            });
        });
        lectures[0].series = lecturesValores;
        return lectures;
    }

    async variableUpdateNeeded(scName: string, varName: string, varSize: number): Promise<boolean>
    {
        return new Promise((resolve)=>
        {
            firebase.firestore().collection(colLect).limit(1).get().then(querySnapshot =>
            {
                querySnapshot.docs[0].ref.collection(scName).limit(1).get().then(querySnapshot =>
                {
                    querySnapshot.docs[0].ref.collection(varName).get().then((snapshot) => 
                    { 
                        resolve(snapshot.docs.length > varSize);
                    });
                });
            });
        })
    }

    getUserProjects(): Observable<any[]> 
    {
        // trigger the query
        this.userFilter$.next(this.user.uid);

        return this.userFilter$.pipe(switchMap(user =>this.db.collection(colProy, ref => ref.where('id_user', '==', user)).valueChanges({idField: '_id'})));
    }

    getUserCentroProjects(centroId): Observable<any[]> 
    {
        // trigger the query
        this.centroFilter$.next(centroId);

        return this.centroFilter$.pipe(
        switchMap(centro =>
        this.db.collection(colProy, ref => ref.where('centro', '==', centroId)).valueChanges(
        { 
            idField: '_id' 
        })));
    }

    deleteProject(reference) 
    {
        this.db.collection(colProy).doc(reference).delete().then(() => 
        {
            console.log("Document successfully deleted!");
        })
        .catch((error) => 
        {
            console.error("Error removing document: ", error);
        });
    }

    deleteCentro(reference) 
    {
        this.db.collection(colCentro).doc(reference).delete().then(() => 
        {
            console.log("Document successfully deleted!");
        })
        .catch((error) => 
        {
            console.error("Error removing document: ", error);
        });
    }

    createNewUser() 
    {
        let newUser = new Usuario(this.user.uid, this.user.email, '', '', [], [],'Avanzada');
        this.db.collection(colUsu).doc(this.user.uid).set(Object.assign({}, newUser));
    }

    createNewUserWithId(id, email, nombre, centro, devices) 
    {
        let newUser = new Usuario(id, email, nombre, centro, devices, [], 'Avanzada');
        this.db.collection(colUsu).doc(id).set(Object.assign({}, newUser));
    }
    
    createNewProject(tipo): string 
    {
        let id = this.db.createId();
        let newProj = new Proyecto(id, this.user.uid, 'Proyecto sin nombre', '', '', '', '', '', [], tipo);
        console.log('asdfasd',newProj);
        console.log(newProj);
        this.db.collection(colProy).doc(id).set(Object.assign({}, newProj));
        return id;
    }

    duplicateProject(proj): string 
    {
        let id = this.db.createId();
        let centro = proj.centro !== null ? proj.centro : '';
        let url = proj.url_img !== null ? proj.url_img : '';
        let desc = proj.project_description !== null ? proj.project_description : '';
        let version = proj.version_sucre ? proj.version_sucre : 'Avanzada';
        console.log('version', version);
        let newProj = new Proyecto(id, this.user.uid, 'Copia de ' + proj.project_name, centro, proj.xml, proj.mod_date, url, desc, proj.tags/*, proj.colMontajes*/, version);
        this.db.collection(colProy).doc(id).set(Object.assign({}, newProj));
        return id;
    }

    createNewCentro(name, email): string 
    {
        let id = this.db.createId();
        let newCen = new Centro(id, '', email, name, []);
        this.db.collection(colCentro).doc(id).set(Object.assign({}, newCen));
        return id;
    }
    
    getProyectosOrdenados(proyectos: Proyecto[]): Proyecto[] 
    {
        const copiaProyectos = JSON.parse(JSON.stringify(proyectos));
        
        copiaProyectos.sort((a, b) => 
        {
            const fechaA = new Date(a.mod_date);
            const fechaB = new Date(b.mod_date);
            return fechaB.getTime() - fechaA.getTime();
        });
        
        return copiaProyectos;
    }

    updateProject(name, ref, xml, c, desc) 
    {
        let date = new Date().toString();
        desc = desc ? desc : '';
        this.db.collection(colProy).doc(ref).update({ project_name: name, centro: c, xml: xml, mod_date: date, project_description: desc});
    }

    updateProjectImage(ref, url) 
    {
        let date = new Date().toString();
        this.db.collection(colProy).doc(ref).update({ mod_date: date, url_img: url });
    }

    updateProjectTags(ref, tags) 
    {
        let date = new Date().toString();
        this.db.collection(colProy).doc(ref).update({ mod_date: date, tags: tags });
    }

    updateUserDevices(uid, devs) 
    {
        this.db.collection(colUsu).doc(uid).update({ devices: devs });
    }

    updateUserCentro(uid, centroId) 
    {
        this.db.collection(colUsu).doc(uid).update({ centro: centroId });
    }

    updateUser(uid, nam, devs, centroId, tipoSucre) 
    {
        if (!nam) 
        {
            nam = '';
        }
        if (!centroId) 
        {
            centroId = '';
        }
        if(!tipoSucre)
        {
            tipoSucre = 'Avanzada';
        }
        
        this.db.collection(colUsu).doc(uid).update({ name: nam, centro: centroId, devices: devs, version_sucre: tipoSucre});
    }

    updateCentroAdmin(id_centro, id_admin) 
    {
        this.db.collection(colCentro).doc(id_centro).update({ id_user_admin: id_admin });
    }

    updateCentroTags(id_centro, tgs) 
    {
        this.db.collection(colCentro).doc(id_centro).update({ tags: tgs });
    }
}
