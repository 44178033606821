/// <reference types="web-bluetooth" />
import { Injectable } from '@angular/core';
import { delay } from 'rxjs/operators';

@Injectable()
export class BluetoothService{
    private _navigator:any;
    private wifiSuccessCharacteristic:any;
    private ssidCharacteristic:any;
    private pwdCharacteristic:any;
    private wifiListCharacteristic:any;
    private refreshWifiCharacteristic:any;
    private securityCharacteristic:any;
    private checkCredentialsCharacteristic:any;
    private deleteWifiCharacteristic:any;
    private bleDevice:any;
    private connected:boolean;
    private deviceHasWifi:boolean;
    private connectedWifiName:String = '';
    private connectingBLE: boolean = false;
    private wifiList:String = '';
    private connectResult: String;
    private refreshingWifiList: boolean;
    private deletingWifi: boolean;
    private bluetoothDisconnect: boolean;

    constructor(){
        this._navigator = navigator
        this.connected = false;
    }

    //Función para esperar a que se escriban valores en las
    //características Bluetooth
    private async delayPromise(delay) {
      return new Promise(resolve => {
       setTimeout(resolve, delay);
     });
    }

    async connectWifi(ssid,sec,pwd){     
      const encoder = new TextEncoder();
      this.connectResult = "connecting";
      //Introduce la SSID en la Argon
      await this.ssidCharacteristic.writeValue(encoder.encode(ssid))
        .catch(err => console.log('Error in getSSIDCharacteristic', err))
        .then(async () => this.delayPromise(1000))
        .then(async () => {
          
          //Introduce la seguridad en la Argon
          await this.securityCharacteristic.writeValue(encoder.encode(sec))
            .catch(err => console.log('Error in changeWiFiSec', err))
            .then(async () => this.delayPromise(1000))
            .then(async () => {

            //Introduce la password en la Argon
              await this.pwdCharacteristic.writeValue(encoder.encode(pwd))
              .catch(err => console.log('Error in changeWiFiPass', err))
              .then(async () => this.delayPromise(1000))
              .then(async () => {

                //Espera a que se acabe de realizar la conexión y se almacena el resultado
                var decoder = new TextDecoder();
                var valor;
                
                /*.catch(err => console.log('Error in wifiSuccessReceiver read', err))
                .then(() => this.delayPromise(100));*/
          
                while(this.connectResult === "connecting"){
                  valor = await this.wifiSuccessCharacteristic.readValue();
                  await this.delayPromise(100);
                  this.connectResult = decoder.decode(valor);
                }
                console.log(this.connectResult);
                this.checkWifi();
              })
            })
        })
    }
/*
    async setWifiName(){
      var decoder = new TextDecoder();
      var valor;
      if(this.connectResult){
        while(this.connectedWifiName === "#"){
          valor = await this.checkCredentialsCharacteristic.readValue();
          await this.delayPromise(100);
          this.connectedWifiName = decoder.decode(valor);
        }
      }
      console.log(this.connectedWifiName);
    }*/

    async checkWifi(){
      var decoder = new TextDecoder();
      var valor;

      await this.delayPromise(1000);
      valor = await this.checkCredentialsCharacteristic.readValue();
      await this.delayPromise(1000);
      this.connectedWifiName = decoder.decode(valor);
      console.log(this.connectedWifiName);

      if(this.connectedWifiName != "#"){
        this.deviceHasWifi=true;
      }
      else{
        this.deviceHasWifi = false;
        this.wifiListReceiver();
      }
      this.connected = true;
      this.connectingBLE = false; 
    }

    async deleteWifi(){
      this.deletingWifi = true;
      const encoder = new TextEncoder();
      this.deleteWifiCharacteristic.writeValue(encoder.encode("delete"))
        .catch(err => console.log('Error in deleteWifi write', err))
        .then(async () => this.delayPromise(100))
        .then(async() =>{
          this.wifiListReceiver();
          this.connectedWifiName = "#";
          this.deviceHasWifi = false;
          this.connectResult = "";
        })
      this.deletingWifi = false;
    }

    //Recoge la lista de APs que capta la Argon
    async wifiListReceiver() {
      this.refreshingWifiList = true;
      const listaAntigua = this.wifiList;
      var valor;
      var decoder = new TextDecoder();

      const encoder = new TextEncoder();
      this.refreshWifiCharacteristic.writeValue(encoder.encode("refresh"))
        .catch(err => {
          this.refreshingWifiList = false;
          console.log('Error in wifiListReceiver write', err)})
        .then(async () => this.delayPromise(100))
        .then(async () => {
          
          while(listaAntigua == this.wifiList){
            valor = await this.wifiListCharacteristic.readValue();
            await this.delayPromise(100);
            this.wifiList = decoder.decode(valor);
          }
          this.refreshingWifiList = false;
        })
    }

    //Devuelve la lista de redes wifi
    getWifiList(){
      return this.wifiList;
    }

    //Devuelve nombre de la wifi a la que está conectada
    getWifiName(){
      return this.connectedWifiName;
    }

    //Funciones para comprobar si hay procesos en marcha
    isConnected(){
      return this.connected;
    }

    isRefreshingWifiList(){
      return this.refreshingWifiList;
    }

    isConnectingBLE(){
      return this.connectingBLE;
    }

    getConnectionResult(){
      return this.connectResult;
    }

    hasWifi(){
      return this.deviceHasWifi;
    }

    isDeletingWifi(){
      return this.deletingWifi;
    }

    bleDisconnect(){
      return this.bluetoothDisconnect;
    }
    
    //Cierra la conexión entre la Argon y la aplicación
    disconnect(){
      this.bleDevice.gatt.disconnect();
      this.connected = false;
    }

    //Conexión por Bluetooth y adquisición de las características
    connect(bleFilters){
        console.log('Requesting any Bluetooth Device...');
        navigator.bluetooth.requestDevice(bleFilters)
        .then(device => {
          console.log('Connecting to GATT Server...');
          var self = this;
          this.bluetoothDisconnect=false;
          device.addEventListener('gattserverdisconnected', function(){self.bluetoothDisconnect=true;self.disconnect();});
          this.bleDevice = device;
          this.connectingBLE = true;
          return device.gatt.connect();
        })
        .then(server => {
          console.log('Getting Device Information Service...');
          return server.getPrimaryService('5c1b9a0d-b5be-4a40-8f7a-66b36d0a5176');
        })
        .then(service => {
          console.log('Getting Device Information Characteristics...');
          return service.getCharacteristics();
        })
        .then(characteristics => {
          characteristics.forEach(characteristic => {
            
            if (characteristic.uuid.toString() === 'fdcf0001-3fed-4ed2-84e6-04bbb9ae04d4'){
              this.wifiSuccessCharacteristic = characteristic;
            }

            else if (characteristic.uuid.toString() === 'fdcf0002-3fed-4ed2-84e6-04bbb9ae04d4') {
              this.refreshWifiCharacteristic = characteristic;
            }

            else if (characteristic.uuid.toString() === 'fdcf0003-3fed-4ed2-84e6-04bbb9ae04d4'){
              this.ssidCharacteristic = characteristic;
            }

            else if (characteristic.uuid.toString() === 'fdcf0004-3fed-4ed2-84e6-04bbb9ae04d4') {
              this.pwdCharacteristic = characteristic;
            }

            else if (characteristic.uuid.toString() === 'fdcf0005-3fed-4ed2-84e6-04bbb9ae04d4') {
              this.wifiListCharacteristic = characteristic;
            }

            else if (characteristic.uuid.toString() === 'fdcf0006-3fed-4ed2-84e6-04bbb9ae04d4') {
              this.securityCharacteristic = characteristic;
            }

            else if (characteristic.uuid.toString() === 'fdcf0007-3fed-4ed2-84e6-04bbb9ae04d4') {
              this.checkCredentialsCharacteristic = characteristic;
            }

            else if (characteristic.uuid.toString() === 'fdcf0008-3fed-4ed2-84e6-04bbb9ae04d4') {
              this.deleteWifiCharacteristic = characteristic;
            }})
          //Escaneamos las redes WiFi
          this.checkWifi();
      })
        .catch(error => {
          console.log('Argh! ' + error);
          this.connected = false;
        });
      }
}