import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';

import { NgxBlocklyConfig, NgxBlocklyGeneratorConfig } from 'ngx-blockly';

import { ParticleService } from '../../controller/particle.service';

import { DatabaseService } from '../../controller/database.service';

var selected;

@Component
({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.css'],
  encapsulation: ViewEncapsulation.None
}
)
export class BlockComponent implements OnInit 
{

  code: string = 'No hay código aun';
  devices = [];
  fbDevices;
  filteredDevices = [];
  selectedDevide: string;
  core: boolean = false;
  public innerHeight: any;
  public innerHeightTab: any;
  public height: any;
  public config
  public width: Boolean;
  hide: boolean = false;
  project_name: string;

  @HostListener('window:resize', ['$event'])
  onResize(event) 
  {
    this.innerHeight = (window.innerHeight - 140) + "px";
    this.innerHeightTab = (window.innerHeight - 190) + "px";
    (window.innerWidth < 767) ? this.width = false : this.width = true;

    (window.innerWidth < 767) ? this.height = this.innerHeightTab : this.height = this.innerHeight;
    (window.innerWidth < 767) ? this.config = this.configMovil : this.config = this.configWeb;
  }

  constructor(public particle: ParticleService, public db: DatabaseService) { }

/*
#############################################################################################################
#############################################################################################################
############################ VERSION AVANZADA ###########################################################
#############################################################################################################
#############################################################################################################
*/

/*
#############################################################################################################
########################### VERSION WEB AVANZADA ############################################################
#############################################################################################################
*/
public configWebAvanzado: NgxBlocklyConfig = 
{
  toolbox: 
  `<xml id="toolbox" style="display: none">
    <category name="Lógica" colour="60">
    <block type="controls_if"></block>
    <block type="logic_compare"></block>
    <block type="logic_operation"></block>
    <block type="logic_negate"></block>
    <block type="logic_null"></block>
  </category>

  <category name="Control" colour="40">
    <block type="base_delay">
      <value name="DELAY_TIME">
        <block type="math_number">
          <field name="NUM">1000</field>
        </block>
      </value>
    </block>
    <block type="controls_for">
      <value name="FROM">
        <block type="math_number">
          <field name="NUM">1</field>
        </block>
      </value>
      <value name="TO">
        <block type="math_number">
          <field name="NUM">10</field>
        </block>
      </value>
    </block>
    <block type="controls_whileUntil"></block>
  </category>

  <category name="Op. matemáticas" colour="20">
    <block type="math_number"></block>
    <block type="math_arithmetic"></block>
    <block type="base_map">
      <value name="DMAX">
        <block type="math_number">
          <field name="NUM">180</field>
        </block>
      </value>
    </block>
    <block type="base_map_2">
      <value name="Num1">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
      <value name="Num2">
        <block type="math_number">
          <field name="NUM">1024</field>
        </block>
      </value>
      <value name="Num3">
        <block type="math_number">
          <field name="NUM">10</field>
        </block>
      </value>
    </block>
  </category>

  <category name="Texto" colour="0">
    <block type="text"></block>
  </category>

  <category name="Variables" colour="340" custom="VARIABLE">  </category>

  <category name="Funciones" colour="320" custom="PROCEDURE">  </category>
  
  <sep></sep>
  <category name="Sensores" colour="210">
    <block type="sensor_temperatura"></block>
    <block type="sensor_humedad_aire"></block>
    <block type="sensor_distancia"></block>
    <block type="sensor_luz"></block>
    <block type="sensor_humedad_suelo"></block>
    <block type="sensor_ruido"></block>
    <block type="sensor_boton"></block>
    <block type="sensor_angulo"></block>
  </category>

  <category name="Actuadores" colour="140" >
    <block type="actuador_led"></block>
    <block type="actuador_barra_led"></block>
    <block type="actuador_led_variable"></block>
    <block type="actuador_rele"></block>
    <block type="actuador_pantalla_digitos"></block>
    <block type="actuador_zumbador"></block>
    <category name="música" colour="140">
        <block type="nota"></block>
    </category>
    <category name="Pantalla OLED"  colour="140">
      <block type="actuador_OLED_mover"></block>
      <block type="actuador_OLED_borrar"></block>
      <block type="actuador_OLED_insertar"></block>
    </category>
  </category>

  <category name="Conjuntos guardados" colour = "350">
    
  </category>

  <category name="Online" colour="280">
    <block type="particle_publish2"></block>
    <block type="particle_subs2"></block>
    <block type="save_data"></block>
  </category>

  <category name="Games" colour="300">
  <block type="particle_publishGame"></block>
  <block type="particle_publishGamePubSub2"></block>
</category>
              
  </xml>`,
  scrollbars: true,
  trashcan: true
};

/*
#############################################################################################################
########################### VERSION MOVIL AVANZADO ##########################################################
#############################################################################################################
*/
public configMovilAvanzado: NgxBlocklyConfig = 
{
toolbox: 
`<xml id="toolbox" style="display: none">
  <category name="Lógica" colour="60">
  <block type="controls_if"></block>
  <block type="logic_compare"></block>
  <block type="logic_operation"></block>
  <block type="logic_negate"></block>
  <block type="logic_null"></block>
</category>

<category name="Control" colour="40">
  <block type="base_delay">
    <value name="DELAY_TIME">
      <block type="math_number">
        <field name="NUM">1000</field>
      </block>
    </value>
  </block>
  <block type="controls_for">
    <value name="FROM">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
    <value name="TO">
      <block type="math_number">
        <field name="NUM">10</field>
      </block>
    </value>
  </block>
  <block type="controls_whileUntil"></block>
</category>

<category name="Op. matemáticas" colour="20">
  <block type="math_number"></block>
  <block type="math_arithmetic"></block>
  <block type="base_map">
    <value name="DMAX">
      <block type="math_number">
        <field name="NUM">180</field>
      </block>
    </value>
  </block>
  <block type="base_map_2">
    <value name="Num1">
      <block type="math_number">
        <field name="NUM">0</field>
      </block>
    </value>
    <value name="Num2">
      <block type="math_number">
        <field name="NUM">1024</field>
      </block>
    </value>
    <value name="Num3">
      <block type="math_number">
        <field name="NUM">10</field>
      </block>
    </value>
  </block>
</category>

<category name="Texto" colour="0">
  <block type="text"></block>
</category>

<category name="Variables" colour="340" custom="VARIABLE">  </category>

<category name="Funciones" colour="320" custom="PROCEDURE">  </category>

<sep></sep>
<category name="Sensores" colour="210">
  <block type="sensor_temperatura"></block>
  <block type="sensor_humedad_aire"></block>
  <block type="sensor_distancia"></block>
  <block type="sensor_luz"></block>
  <block type="sensor_humedad_suelo"></block>
  <block type="sensor_ruido"></block>
  <block type="sensor_boton"></block>
  <block type="sensor_angulo"></block>
</category>

<category name="Actuadores" colour="140" >
  <block type="actuador_led"></block>
  <block type="actuador_barra_led"></block>
  <block type="actuador_led_variable"></block>
  <block type="actuador_rele"></block>
  <block type="actuador_pantalla_digitos"></block>
  <block type="actuador_zumbador"></block>
  <category name="música" colour="140">
      <block type="nota"></block>
  </category>
  <category name="Pantalla OLED"  colour="140">
    <block type="actuador_OLED_mover"></block>
    <block type="actuador_OLED_borrar"></block>
    <block type="actuador_OLED_insertar"></block>
  </category>
</category>

<category name="Conjuntos guardados" colour = "350">
  
</category>

<category name="Online" colour="280">
  <block type="particle_publish2"></block>
  <block type="particle_subs2"></block>
  <block type="save_data"></block>
</category>

<category name="Games" colour="300">
  <block type="particle_publishGame"></block>
  <block type="particle_publishGamePubSub2"></block>
</category>
            
</xml>`,
scrollbars: true,
trashcan: true
};

/*
#############################################################################################################
#############################################################################################################
############################ VERSION BÁSICA #################################################################
#############################################################################################################
#############################################################################################################
*/

/*
#############################################################################################################
########################### VERSION WEB BÁSICA ##############################################################
#############################################################################################################
*/
public configWebBasica: NgxBlocklyConfig = 
{
toolbox: 
`<xml id="toolbox" style="display: none">
  <category name="Lógica" colour="60">
    <block type="controls_if"></block>
    <block type="logic_compare"></block>
    <block type="logic_operation"></block>
    <block type="logic_negate"></block>
    <block type="logic_null"></block>
  </category>

  <category name="Control" colour="40">
    <block type="base_delay">
      <value name="DELAY_TIME">
        <block type="math_number">
          <field name="NUM">1000</field>
        </block>
      </value>
    </block>
    <block type="controls_for">
      <value name="FROM">
        <block type="math_number">
          <field name="NUM">1</field>
        </block>
      </value>
      <value name="TO">
        <block type="math_number">
          <field name="NUM">10</field>
        </block>
      </value>
    </block>
    <block type="controls_whileUntil"></block>
  </category>

  <category name="Op. matemáticas" colour="20">
    <block type="math_number"></block>
    <block type="math_arithmetic"></block>
    <block type="base_map">
      <value name="DMAX">
        <block type="math_number">
          <field name="NUM">180</field>
        </block>
      </value>
    </block>
    <block type="base_map_2">
      <value name="Num1">
        <block type="math_number">
          <field name="NUM">0</field>
        </block>
      </value>
      <value name="Num2">
        <block type="math_number">
          <field name="NUM">1024</field>
        </block>
      </value>
      <value name="Num3">
        <block type="math_number">
          <field name="NUM">10</field>
        </block>
      </value>
    </block>
  </category>

  <category name="Texto" colour="0">
    <block type="text"></block>
  </category>

  <category name="Variables" colour="340" custom="VARIABLE">  </category>

  <category name="Funciones" colour="320" custom="PROCEDURE">  </category>

  <sep></sep>
  <category name="Sensores" colour="210">
    <category name="Temperatura" colour="210">
      <block type="calor"></block>
      <block type="frio"></block>
    </category>

    <category name="Distancia" colour="210">
      <block type="cerca"></block>
      <block type="lejos"></block>
    </category>

    <category name="Luz" colour="210">
      <block type="luz"></block>
      <block type="noLuz"></block>
    </category>

    <category name="Ruido" colour="210">
      <block type="ruido"></block>
      <block type="silencio"></block>
    </category>

    <category name="Boton" colour="210">
      <block type="boton_pulsado"></block>
      <block type="boton_NO_pulsado"></block>
    </category>
    <category name="Angulo" colour="200">
      <block type="angulo_pequeño"></block>
      <block type="angulo_grande"></block>
    </category>

    <category name="Otros" colour = "210">
      <category name="Humedad suelo" colour="210">
        <block type="suelo_humedo"></block>
        <block type="suelo_seco"></block>
        <block type="suelo_agua"></block>
      </category>
      <category name="Humedad ambiental" colour="210">
        <block type="humedad_aire_poca"></block>
        <block type="humedad_aire_optima"></block>
        <block type="humedad_aire_demasiada"></block>
      </category>
      <category name="Angulo" colour="210">
        <block type="angulo_agudo"></block>
        <block type="angulo_recto"></block>
        <block type="angulo_obtuso"></block>
        <block type="angulo_llano"></block>
        <block type="angulo_concavo"></block>
        <block type="angulo_completo"></block>
      </category>
    </category>
  </category>

  <category name="Actuadores" colour="140" >
    <category name = "Led simple" colour = "140">
      <block type="led_simple_encendido"></block>
      <block type="led_simple_apagado"></block>
    </category>

    <category name = "Led multicolor" colour = "140">
      <block type="led_color_verde"></block>
      <block type="led_color_azul"></block>
      <block type="led_color_morado"></block>
      <block type="led_color_rojo"></block>
      <block type="led_color_naranja"></block>
      <block type="led_color_amarillo"></block>
    </category>

    <category name = "Zumbador" colour = "140">
      <block type="zumbador_encendido"></block>
      <block type="zumbador_apagado"></block>
      </category>
      
      <category name="Música" colour="140">
        <category name="Notas" colour = "140">
          <block type="do"></block>
          <block type="re"></block>
          <block type="mi"></block>
          <block type="fa"></block>
          <block type="sol"></block>
          <block type="la"></block>
          <block type="si"></block>
          <block type="silencio_musica"></block>
        </category>

        <category name="Figuras musicales" colour = "140">
          <block type="negra"></block>
          <block type="blanca"></block>
          <block type="redonda"></block>
          <block type="corchea"></block>
          <block type="semicorchea"></block>
        </category>
      
        <category name="Melodías" colour="140">
          <block type="cumpleanyosFeliz"></block>
          <block type="laCucaracha"></block>
          <block type="cucuCantabaLaRana"></block>
        </category>
      </category>
    
      <category name="Otros" colour ="140">
        <category name = "Barra led" colour = "140">
          <block type="barra_led_0"></block>
          <block type="barra_led_1"></block>
          <block type="barra_led_2"></block>
          <block type="barra_led_3"></block>
          <block type="barra_led_4"></block>
          <block type="barra_led_5"></block>
          <block type="barra_led_6"></block>
          <block type="barra_led_7"></block>
          <block type="barra_led_8"></block>
          <block type="barra_led_9"></block>
          <block type="barra_led_10"></block>
        </category>

        <category name = "Relé" colour = "140">
          <block type="rele_encendido"></block>
          <block type="rele_apagado"></block>
        </category>

        <category name = "Pantalla de dígitos" colour = "140">
          <block type="pantalla_digitos_0000"></block>
          <block type="pantalla_digitos_1111"></block>
          <block type="pantalla_digitos_2222"></block>
          <block type="pantalla_digitos_3333"></block>
          <block type="pantalla_digitos_4444"></block>
          <block type="pantalla_digitos_5555"></block>
          <block type="pantalla_digitos_6666"></block>
          <block type="pantalla_digitos_7777"></block>
          <block type="pantalla_digitos_8888"></block>
          <block type="pantalla_digitos_9999"></block>
        </category>

        <category name="Música" colour = "140">
          <category name="Notas con tiempo desplegable" colour = "140">
            <block type="doDesp"></block>
            <block type="reDesp"></block>
            <block type="miDesp"></block>
            <block type="faDesp"></block>
            <block type="solDesp"></block>
            <block type="laDesp"></block>
            <block type="siDesp"></block>
            <block type="silencio_musicaDesp"></block>
          </category>

          <category name="Figuras con nota desplegable" colour="140">
            <block type="negraDesp"></block>
            <block type="blancaDesp"></block>
            <block type="redondaDesp"></block>
            <block type="corcheaDesp"></block>
            <block type="semicorcheaDesp"></block>
          </category>
        </category>
    </category>
  </category>

  <category name="Online" colour="280">
    <block type="particle_publish2"></block>
    <block type="particle_subs2"></block>
    <block type="save_data"></block>
  </category>

  <category name="Games" colour="300">
  <block type="particle_publishGame"></block>
  <block type="particle_publishGamePubSub2"></block>
</category>
          
</xml>`,
scrollbars: true,
trashcan: true
};

/*
#############################################################################################################
########################## VERSION MOVIL BÁSICA #############################################################
#############################################################################################################
*/

public configMovilBasica: NgxBlocklyConfig = 
{
toolbox: 
`<xml id="toolbox" style="display: none">
<category name="Lógica" colour="60">
<block type="controls_if"></block>
<block type="logic_compare"></block>
<block type="logic_operation"></block>
<block type="logic_negate"></block>
<block type="logic_null"></block>
</category>

<category name="Control" colour="40">
<block type="base_delay">
  <value name="DELAY_TIME">
    <block type="math_number">
      <field name="NUM">1000</field>
    </block>
  </value>
</block>
<block type="controls_for">
  <value name="FROM">
    <block type="math_number">
      <field name="NUM">1</field>
    </block>
  </value>
  <value name="TO">
    <block type="math_number">
      <field name="NUM">10</field>
    </block>
  </value>
</block>
<block type="controls_whileUntil"></block>
</category>

<category name="Op. matemáticas" colour="20">
<block type="math_number"></block>
<block type="math_arithmetic"></block>
<block type="base_map">
  <value name="DMAX">
    <block type="math_number">
      <field name="NUM">180</field>
    </block>
  </value>
</block>
<block type="base_map_2">
  <value name="Num1">
    <block type="math_number">
      <field name="NUM">0</field>
    </block>
  </value>
  <value name="Num2">
    <block type="math_number">
      <field name="NUM">1024</field>
    </block>
  </value>
  <value name="Num3">
    <block type="math_number">
      <field name="NUM">10</field>
    </block>
  </value>
</block>
</category>

<category name="Texto" colour="0">
<block type="text"></block>
</category>

<category name="Variables" colour="340" custom="VARIABLE">  </category>

<category name="Funciones" colour="320" custom="PROCEDURE">  </category>

<sep></sep>
<category name="Sensores" colour="210">
<category name="Temperatura" colour="210">
  <block type="calor"></block>
  <block type="frio"></block>
</category>

<category name="Distancia" colour="210">
  <block type="cerca"></block>
  <block type="lejos"></block>
</category>

<category name="Luz" colour="210">
  <block type="luz"></block>
  <block type="noLuz"></block>
</category>

<category name="Ruido" colour="210">
  <block type="ruido"></block>
  <block type="silencio"></block>
</category>

<category name="Boton" colour="210">
  <block type="boton_pulsado"></block>
  <block type="boton_NO_pulsado"></block>
</category>
<category name="Angulo" colour="200">
  <block type="angulo_pequeño"></block>
  <block type="angulo_grande"></block>
</category>

<category name="Otros" colour = "210">
  <category name="Humedad suelo" colour="210">
    <block type="suelo_humedo"></block>
    <block type="suelo_seco"></block>
    <block type="suelo_agua"></block>
  </category>
  <category name="Humedad ambiental" colour="210">
    <block type="humedad_aire_poca"></block>
    <block type="humedad_aire_optima"></block>
    <block type="humedad_aire_demasiada"></block>
  </category>
  <category name="Angulo" colour="210">
    <block type="angulo_agudo"></block>
    <block type="angulo_recto"></block>
    <block type="angulo_obtuso"></block>
    <block type="angulo_llano"></block>
    <block type="angulo_concavo"></block>
    <block type="angulo_completo"></block>
  </category>
</category>
</category>

<category name="Actuadores" colour="140" >
<category name = "Led simple" colour = "140">
  <block type="led_simple_encendido"></block>
  <block type="led_simple_apagado"></block>
</category>

<category name = "Led multicolor" colour = "140">
  <block type="led_color_verde"></block>
  <block type="led_color_azul"></block>
  <block type="led_color_morado"></block>
  <block type="led_color_rojo"></block>
  <block type="led_color_naranja"></block>
  <block type="led_color_amarillo"></block>
</category>

<category name = "Zumbador" colour = "140">
  <block type="zumbador_encendido"></block>
  <block type="zumbador_apagado"></block>
  </category>
  
  <category name="Música" colour="140">
    <category name="Notas" colour = "140">
      <block type="do"></block>
      <block type="re"></block>
      <block type="mi"></block>
      <block type="fa"></block>
      <block type="sol"></block>
      <block type="la"></block>
      <block type="si"></block>
      <block type="silencio_musica"></block>
    </category>

    <category name="Figuras musicales" colour = "140">
      <block type="negra"></block>
      <block type="blanca"></block>
      <block type="redonda"></block>
      <block type="corchea"></block>
      <block type="semicorchea"></block>
    </category>
  
    <category name="Melodías" colour="140">
      <block type="cumpleanyosFeliz"></block>
      <block type="laCucaracha"></block>
      <block type="cucuCantabaLaRana"></block>
    </category>
  </category>
    
  <category name="Otros" colour ="140">
    <category name = "Barra led" colour = "140">
      <block type="barra_led_0"></block>
      <block type="barra_led_1"></block>
      <block type="barra_led_2"></block>
      <block type="barra_led_3"></block>
      <block type="barra_led_4"></block>
      <block type="barra_led_5"></block>
      <block type="barra_led_6"></block>
      <block type="barra_led_7"></block>
      <block type="barra_led_8"></block>
      <block type="barra_led_9"></block>
      <block type="barra_led_10"></block>
    </category>

    <category name = "Relé" colour = "140">
      <block type="rele_encendido"></block>
      <block type="rele_apagado"></block>
    </category>

    <category name = "Pantalla de dígitos" colour = "140">
      <block type="pantalla_digitos_0000"></block>
      <block type="pantalla_digitos_1111"></block>
      <block type="pantalla_digitos_2222"></block>
      <block type="pantalla_digitos_3333"></block>
      <block type="pantalla_digitos_4444"></block>
      <block type="pantalla_digitos_5555"></block>
      <block type="pantalla_digitos_6666"></block>
      <block type="pantalla_digitos_7777"></block>
      <block type="pantalla_digitos_8888"></block>
      <block type="pantalla_digitos_9999"></block>
    </category>

    <category name="Música" colour = "140">
      <category name="Notas con tiempo desplegable" colour = "140">
        <block type="doDesp"></block>
        <block type="reDesp"></block>
        <block type="miDesp"></block>
        <block type="faDesp"></block>
        <block type="solDesp"></block>
        <block type="laDesp"></block>
        <block type="siDesp"></block>
        <block type="silencio_musicaDesp"></block>
      </category>

      <category name="Figuras con nota desplegable" colour="140">
        <block type="negraDesp"></block>
        <block type="blancaDesp"></block>
        <block type="redondaDesp"></block>
        <block type="corcheaDesp"></block>
        <block type="semicorcheaDesp"></block>
      </category>
    </category>
</category>
</category>

<category name="Online" colour="280">
<block type="particle_publish2"></block>
<block type="particle_subs2"></block>
<block type="save_data"></block>
</category>

<category name="Games" colour="300">
  <block type="particle_publishGame"></block>
  <block type="particle_publishGamePubSub2"></block>
</category>
          
</xml>`,
scrollbars: true,
trashcan: true
};
/*
#############################################################################################################
#############################################################################################################
########################### ConfigWeb #######################################################################
#############################################################################################################
#############################################################################################################
*/
public configWeb: NgxBlocklyConfig = 
{
toolbox: 
`<xml id="toolbox" style="display: none">
  <category name="Lógica" colour="60">
  <block type="controls_if"></block>
  <block type="logic_compare"></block>
  <block type="logic_operation"></block>
  <block type="logic_negate"></block>
  <block type="logic_null"></block>
</category>

<category name="Control" colour="40">
  <block type="base_delay">
    <value name="DELAY_TIME">
      <block type="math_number">
        <field name="NUM">1000</field>
      </block>
    </value>
  </block>
  <block type="controls_for">
    <value name="FROM">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
    <value name="TO">
      <block type="math_number">
        <field name="NUM">10</field>
      </block>
    </value>
  </block>
  <block type="controls_whileUntil"></block>
</category>

<category name="Op. matemáticas" colour="20">
  <block type="math_number"></block>
  <block type="math_arithmetic"></block>
  <block type="base_map">
    <value name="DMAX">
      <block type="math_number">
        <field name="NUM">180</field>
      </block>
    </value>
  </block>
  <block type="base_map_2">
    <value name="Num1">
      <block type="math_number">
        <field name="NUM">0</field>
      </block>
    </value>
    <value name="Num2">
      <block type="math_number">
        <field name="NUM">1024</field>
      </block>
    </value>
    <value name="Num3">
      <block type="math_number">
        <field name="NUM">10</field>
      </block>
    </value>
  </block>
</category>

<category name="Texto" colour="0">
  <block type="text"></block>
</category>

<category name="Variables" colour="340" custom="VARIABLE">  </category>

<category name="Funciones" colour="320" custom="PROCEDURE">  </category>

<sep></sep>
<category name="Sensores" colour="210">
  <category name="Sucre4STEM" colour="210">
    <block type="sensor_temperatura"></block>
    <block type="sensor_humedad_aire"></block>
    <block type="sensor_distancia"></block>
    <block type="sensor_luz"></block>
    <block type="sensor_humedad_suelo"></block>
    <block type="sensor_ruido"></block>
    <block type="sensor_boton"></block>
    <block type="sensor_angulo"></block>
  </category>

  <category name="Sucre4KIDS" colour="210">
    <category name="Temperatura" colour="210">
      <block type="calor"></block>
      <block type="frio"></block>
    </category>

    <category name="Distancia" colour="210">
      <block type="cerca"></block>
      <block type="lejos"></block>
    </category>

    <category name="Luz" colour="210">
      <block type="luz"></block>
      <block type="noLuz"></block>
    </category>

    <category name="Ruido" colour="210">
      <block type="ruido"></block>
      <block type="silencio"></block>
    </category>

    <category name="Boton" colour="210">
      <block type="boton_pulsado"></block>
      <block type="boton_NO_pulsado"></block>
    </category>
    <category name="Angulo" colour="200">
      <block type="angulo_pequeño"></block>
      <block type="angulo_grande"></block>
    </category>

    <category name="Otros" colour = "210">
      <category name="Humedad suelo" colour="210">
        <block type="suelo_humedo"></block>
        <block type="suelo_seco"></block>
        <block type="suelo_agua"></block>
      </category>
      <category name="Humedad ambiental" colour="210">
        <block type="humedad_aire_poca"></block>
        <block type="humedad_aire_optima"></block>
        <block type="humedad_aire_demasiada"></block>
      </category>
      <category name="Angulo" colour="210">
        <block type="angulo_agudo"></block>
        <block type="angulo_recto"></block>
        <block type="angulo_obtuso"></block>
        <block type="angulo_llano"></block>
        <block type="angulo_concavo"></block>
        <block type="angulo_completo"></block>
      </category>
    </category>
  </category>
</category>

<category name="Actuadores" colour="140" >
  <category name = "Sucre4STEM" colour="140">
    <block type="actuador_led"></block>
    <block type="actuador_barra_led"></block>
    <block type="actuador_led_variable"></block>
    <block type="actuador_rele"></block>
    <block type="actuador_pantalla_digitos"></block>
    <block type="actuador_zumbador"></block>
    <category name="música" colour="140">
        <block type="nota"></block>
    </category>
    <category name="Pantalla OLED"  colour="140">
      <block type="actuador_OLED_mover"></block>
      <block type="actuador_OLED_borrar"></block>
      <block type="actuador_OLED_insertar"></block>
    </category>
  </category>

  <category name= "Sucre4KIDS" colour = "140">
    <category name = "Led simple" colour = "140">
      <block type="led_simple_encendido"></block>
      <block type="led_simple_apagado"></block>
    </category>

    <category name = "Led multicolor" colour = "140">
      <block type="led_color_verde"></block>
      <block type="led_color_azul"></block>
      <block type="led_color_morado"></block>
      <block type="led_color_rojo"></block>
      <block type="led_color_naranja"></block>
      <block type="led_color_amarillo"></block>
    </category>

    <category name = "Zumbador" colour = "140">
      <block type="zumbador_encendido"></block>
      <block type="zumbador_apagado"></block>
      </category>
      
      <category name="música" colour="140">
        <category name="Notas" colour = "140">
          <block type="do"></block>
          <block type="re"></block>
          <block type="mi"></block>
          <block type="fa"></block>
          <block type="sol"></block>
          <block type="la"></block>
          <block type="si"></block>
          <block type="silencio_musica"></block>
        </category>

        <category name="Figuras musicales" colour = "140">
          <block type="negra"></block>
          <block type="blanca"></block>
          <block type="redonda"></block>
          <block type="corchea"></block>
          <block type="semicorchea"></block>
        </category>
      <category name="Melodías" colour="140">
          <block type="cumpleanyosFeliz"></block>
          <block type="laCucaracha"></block>
          <block type="cucuCantabaLaRana"></block>
        </category>
        </category>
        <category name="Otros" colour ="140">
          <category name = "Barra led" colour = "140">
            <block type="barra_led_0"></block>
            <block type="barra_led_1"></block>
            <block type="barra_led_2"></block>
            <block type="barra_led_3"></block>
            <block type="barra_led_4"></block>
            <block type="barra_led_5"></block>
            <block type="barra_led_6"></block>
            <block type="barra_led_7"></block>
            <block type="barra_led_8"></block>
            <block type="barra_led_9"></block>
            <block type="barra_led_10"></block>
          </category>

          <category name = "Relé" colour = "140">
            <block type="rele_encendido"></block>
            <block type="rele_apagado"></block>
          </category>
  
          <category name = "Pantalla de dígitos" colour = "140">
            <block type="pantalla_digitos_0000"></block>
            <block type="pantalla_digitos_1111"></block>
            <block type="pantalla_digitos_2222"></block>
            <block type="pantalla_digitos_3333"></block>
            <block type="pantalla_digitos_4444"></block>
            <block type="pantalla_digitos_5555"></block>
            <block type="pantalla_digitos_6666"></block>
            <block type="pantalla_digitos_7777"></block>
            <block type="pantalla_digitos_8888"></block>
            <block type="pantalla_digitos_9999"></block>
          </category>

          <category name="Música" colour = "140">
            <category name="Notas desplegables" colour = "140">
              <block type="doDesp"></block>
              <block type="reDesp"></block>
              <block type="miDesp"></block>
              <block type="faDesp"></block>
              <block type="solDesp"></block>
              <block type="laDesp"></block>
              <block type="siDesp"></block>
              <block type="silencio_musicaDesp"></block>
            </category>

            <category name="Figuras con nota desplegable" colour="140">
              <block type="negraDesp"></block>
              <block type="blancaDesp"></block>
              <block type="redondaDesp"></block>
              <block type="corcheaDesp"></block>
              <block type="semicorcheaDesp"></block>
            </category>
          </category>
    </category>
  </category>
</category>
<category name="Conjuntos guardados" colour = "350">
  
</category>

<category name="Online" colour="280">
  <block type="particle_publish2"></block>
  <block type="particle_subs2"></block>
  <block type="save_data"></block>
</category>

<category name="Games" colour="300">
  <block type="particle_publishGame"></block>
  <block type="particle_publishGamePubSub2"></block>
</category>
            
</xml>`,
scrollbars: true,
trashcan: true
};

/*
#############################################################################################################
#############################################################################################################
########################### ConfigMovil #####################################################################
#############################################################################################################
#############################################################################################################
*/
public configMovil: NgxBlocklyConfig = 
{
toolbox: 
`<xml id="toolbox" style="display: none">
  <category name="Lógica" colour="60">
  <block type="controls_if"></block>
  <block type="logic_compare"></block>
  <block type="logic_operation"></block>
  <block type="logic_negate"></block>
  <block type="logic_null"></block>
</category>

<category name="Control" colour="40">
  <block type="base_delay">
    <value name="DELAY_TIME">
      <block type="math_number">
        <field name="NUM">1000</field>
      </block>
    </value>
  </block>
  <block type="controls_for">
    <value name="FROM">
      <block type="math_number">
        <field name="NUM">1</field>
      </block>
    </value>
    <value name="TO">
      <block type="math_number">
        <field name="NUM">10</field>
      </block>
    </value>
  </block>
  <block type="controls_whileUntil"></block>
</category>

<category name="Op. matemáticas" colour="20">
  <block type="math_number"></block>
  <block type="math_arithmetic"></block>
  <block type="base_map">
    <value name="DMAX">
      <block type="math_number">
        <field name="NUM">180</field>
      </block>
    </value>
  </block>
  <block type="base_map_2">
    <value name="Num1">
      <block type="math_number">
        <field name="NUM">0</field>
      </block>
    </value>
    <value name="Num2">
      <block type="math_number">
        <field name="NUM">1024</field>
      </block>
    </value>
    <value name="Num3">
      <block type="math_number">
        <field name="NUM">10</field>
      </block>
    </value>
  </block>
</category>

<category name="Texto" colour="0">
  <block type="text"></block>
</category>

<category name="Variables" colour="340" custom="VARIABLE">  </category>

<category name="Funciones" colour="320" custom="PROCEDURE">  </category>

<sep></sep>
<category name="Sensores" colour="210">
  <category name="Sucre4STEM" colour="210">
    <block type="sensor_temperatura"></block>
    <block type="sensor_humedad_aire"></block>
    <block type="sensor_distancia"></block>
    <block type="sensor_luz"></block>
    <block type="sensor_humedad_suelo"></block>
    <block type="sensor_ruido"></block>
    <block type="sensor_boton"></block>
    <block type="sensor_angulo"></block>
  </category>

  <category name="Sucre4KIDS" colour="210">
    <category name="Temperatura" colour="210">
      <block type="calor"></block>
      <block type="frio"></block>
    </category>

    <category name="Distancia" colour="210">
      <block type="cerca"></block>
      <block type="lejos"></block>
    </category>

    <category name="Luz" colour="210">
      <block type="luz"></block>
      <block type="noLuz"></block>
    </category>

    <category name="Ruido" colour="210">
      <block type="ruido"></block>
      <block type="silencio"></block>
    </category>

    <category name="Boton" colour="210">
      <block type="boton_pulsado"></block>
      <block type="boton_NO_pulsado"></block>
    </category>
    <category name="Angulo" colour="200">
      <block type="angulo_pequeño"></block>
      <block type="angulo_grande"></block>
    </category>

    <category name="Otros" colour = "210">
      <category name="Humedad suelo" colour="210">
        <block type="suelo_humedo"></block>
        <block type="suelo_seco"></block>
        <block type="suelo_agua"></block>
      </category>
      <category name="Humedad ambiental" colour="210">
        <block type="humedad_aire_poca"></block>
        <block type="humedad_aire_optima"></block>
        <block type="humedad_aire_demasiada"></block>
      </category>
      <category name="Angulo" colour="210">
        <block type="angulo_agudo"></block>
        <block type="angulo_recto"></block>
        <block type="angulo_obtuso"></block>
        <block type="angulo_llano"></block>
        <block type="angulo_concavo"></block>
        <block type="angulo_completo"></block>
      </category>
    </category>
  </category>
</category>

<category name="Actuadores" colour="140" >
  <category name = "Sucre4STEM" colour="140">
    <block type="actuador_led"></block>
    <block type="actuador_barra_led"></block>
    <block type="actuador_led_variable"></block>
    <block type="actuador_rele"></block>
    <block type="actuador_pantalla_digitos"></block>
    <block type="actuador_zumbador"></block>
    <category name="música" colour="140">
        <block type="nota"></block>
    </category>
    <category name="Pantalla OLED"  colour="140">
      <block type="actuador_OLED_mover"></block>
      <block type="actuador_OLED_borrar"></block>
      <block type="actuador_OLED_insertar"></block>
    </category>
  </category>

  <category name= "Sucre4KIDS" colour = "140">
    <category name = "Led simple" colour = "140">
      <block type="led_simple_encendido"></block>
      <block type="led_simple_apagado"></block>
    </category>

    <category name = "Led multicolor" colour = "140">
      <block type="led_color_verde"></block>
      <block type="led_color_azul"></block>
      <block type="led_color_morado"></block>
      <block type="led_color_rojo"></block>
      <block type="led_color_naranja"></block>
      <block type="led_color_amarillo"></block>
    </category>

    <category name = "Zumbador" colour = "140">
      <block type="zumbador_encendido"></block>
      <block type="zumbador_apagado"></block>
      </category>
      
      <category name="música" colour="140">
        <category name="Notas" colour = "140">
          <block type="do"></block>
          <block type="re"></block>
          <block type="mi"></block>
          <block type="fa"></block>
          <block type="sol"></block>
          <block type="la"></block>
          <block type="si"></block>
          <block type="silencio_musica"></block>
        </category>

        <category name="Figuras musicales" colour = "140">
          <block type="negra"></block>
          <block type="blanca"></block>
          <block type="redonda"></block>
          <block type="corchea"></block>
          <block type="semicorchea"></block>
        </category>
      <category name="Melodías" colour="140">
          <block type="cumpleanyosFeliz"></block>
          <block type="laCucaracha"></block>
          <block type="cucuCantabaLaRana"></block>
        </category>
        </category>
        <category name="Otros" colour ="140">
          <category name = "Barra led" colour = "140">
            <block type="barra_led_0"></block>
            <block type="barra_led_1"></block>
            <block type="barra_led_2"></block>
            <block type="barra_led_3"></block>
            <block type="barra_led_4"></block>
            <block type="barra_led_5"></block>
            <block type="barra_led_6"></block>
            <block type="barra_led_7"></block>
            <block type="barra_led_8"></block>
            <block type="barra_led_9"></block>
            <block type="barra_led_10"></block>
          </category>

          <category name = "Relé" colour = "140">
            <block type="rele_encendido"></block>
            <block type="rele_apagado"></block>
          </category>
  
          <category name = "Pantalla de dígitos" colour = "140">
            <block type="pantalla_digitos_0000"></block>
            <block type="pantalla_digitos_1111"></block>
            <block type="pantalla_digitos_2222"></block>
            <block type="pantalla_digitos_3333"></block>
            <block type="pantalla_digitos_4444"></block>
            <block type="pantalla_digitos_5555"></block>
            <block type="pantalla_digitos_6666"></block>
            <block type="pantalla_digitos_7777"></block>
            <block type="pantalla_digitos_8888"></block>
            <block type="pantalla_digitos_9999"></block>
          </category>

          <category name="Música" colour = "140">
            <category name="Notas desplegables" colour = "140">
              <block type="doDesp"></block>
              <block type="reDesp"></block>
              <block type="miDesp"></block>
              <block type="faDesp"></block>
              <block type="solDesp"></block>
              <block type="laDesp"></block>
              <block type="siDesp"></block>
              <block type="silencio_musicaDesp"></block>
            </category>

            <category name="Figuras con nota desplegable" colour="140">
              <block type="negraDesp"></block>
              <block type="blancaDesp"></block>
              <block type="redondaDesp"></block>
              <block type="corcheaDesp"></block>
              <block type="semicorcheaDesp"></block>
            </category>
          </category>
    </category>
  </category>
</category>
<category name="Conjuntos guardados" colour = "350">
  
</category>

<category name="Online" colour="280">
  <block type="particle_publish2"></block>
  <block type="particle_subs2"></block>
  <block type="save_data"></block>
</category>

<category name="Games" colour="300">
  <block type="particle_publishGame"></block>
  <block type="particle_publishGamePubSub2"></block>
</category>
            
</xml>`,
scrollbars: true,
trashcan: true
};

  

  public generatorConfig: NgxBlocklyGeneratorConfig = 
  {
    dart: true,
    javascript: false,
    lua: false,
    php: false,
    python: false,
    xml: true
  };

  onCode(code: string) 
  {
    this.code = code;
  }

  async delay(ms: number) 
  {
    return await new Promise(resolve => setTimeout(resolve, ms));
  }

  async getParticleDevices() 
  {

    this.devices = this.particle.getDevices();

    this.fbDevices.devices.forEach(fireId => 
      {
      this.devices.forEach(Particle => 
      {
        if (fireId === Particle.id) 
        {
          this.filteredDevices.push(Particle)
        }
      });
    });
    this.core = true;
    this.selectedDevide = this.filteredDevices[0].id
    selected = this.selectedDevide

  }

  async getData()
  {
    await this.delay(1000).then(data => this.getFbDevices())
  }

  change() {
    selected = this.selectedDevide
  }

  getFbDevices() {
    this.db.getCurrentUser().subscribe(user => {
      this.fbDevices = user.devices;
      this.getParticleDevices();
    })
  }

  flash() {
    this.particle.flashCode(this.code, selected)
  }

  hideMenu() 
  {
    if (this.hide) 
    {
      this.config = this.configMovil;
      this.hide = false
    }
    else 
    {
      this.config = { trashcan: true };
      this.hide = true
    }

  }
  ngOnInit() 
  {
    //this.getData();
    this.innerHeight = (window.innerHeight - 140) + "px";
    this.innerHeightTab = (window.innerHeight - 190) + "px";

    (window.innerWidth < 767) ? this.height = this.innerHeightTab : this.height = this.innerHeight;
    /*CÓDIGO PARA CARGAR EL MENU ¿?*/
    /*
    let tipoSucre: string;
       
        this.db.getProyectoById(this.ref).subscribe((proyecto: Proyecto) => 
        {
          tipoSucre = proyecto.version_sucre;
          console.log('El proyecto mostrará el menú con lo bloques de la versión: ',tipoSucre); // Aquí puedes utilizar el valor de version_sucre como desees
        }, (error: any) => 
        {
          console.error('Ocurrió un error al obtener el proyecto:', error);
        });
      
        if(window.innerWidth < 767)
        { 
          console.log('Ancho movil:', window.innerWidth);
          if(tipoSucre == 'Basica')          
          {
            console.log('Movil. TipoSucre=Basica');
            this.config = this.configMovilBasica;
          }
          else if(tipoSucre == 'Avanzada')
          {
            console.log('Movil. TipoSucre=Avanzada');
            this.config = this.configMovilAvanzado;
          }
          else
          {
            console.log('Movil. TipoSucre=Ninguno ¿?');
            this.config = this.configMovil;
          }
        }
        else
        {
          console.log('Ancho pc:', window.innerWidth);
          if(tipoSucre == 'Basica')
          {
            console.log('Pc. TipoSucre=Basica');
            this.config = this.configWebBasica;
          }
          else if(tipoSucre == 'Avanzada')
          {
            console.log('Pc. TipoSucre=Avanzada');
            this.config = this.configWebAvanzado;
          }
          else
          {
            console.log('Pc. TipoSucre=Ninguno¿?');
            this.config = this.configWeb;
          }
        }
    */
    
    (window.innerWidth < 767) ? this.config = this.configMovil : this.config = this.configWeb;
    
    (window.innerWidth < 767) ? this.width = false : this.width = true;
    this.project_name = localStorage.getItem("name");
  }

}
