//Importar dependencias
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DatabaseService } from './controller/database.service';
import { AngularFireAuth } from "@angular/fire/auth";

import { MatMenu } from '@angular/material';



@Component
({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent 
{
    user = null;

    admin = false;
    adminusucentro = false;


    // @ViewChild(MatMenu) public menuComponent: MatMenu;

    constructor(public afAuth: AngularFireAuth, public db: DatabaseService) 
    {
        this.afAuth.auth.onAuthStateChanged(user => 
        {
            if (user) 
            {
                // User is signed in.
                this.user = user;
                this.db.subscribeToAdmins().subscribe(admins => 
                {
                    if (admins.user_id.includes(user.uid)) 
                    {
                        this.admin = true;
                    } 
                    else 
                    {
                        this.admin = false;
                    }
                });
                
                this.db.subscribeToCentros().subscribe(centros => 
                {
                    centros.forEach(c => 
                    {
                        if (c.id_user_admin === user.uid) 
                        {
                            this.adminusucentro = true;
                            console.log('USUARIO ES ADMIN CENTRO');
                        } 
                    });
                });
            } 
            else 
            {
                // No user is signed in.
                this.user = null;
                this.admin = false;
                this.adminusucentro = false;
            }
        });
    }

    ngOnInit() {}

    logOut() 
    {
        console.log("*****LOG OUT");
        this.user = null;
        this.admin = false;
        this.adminusucentro = false;
        this.afAuth.auth.signOut();
    }


/*   public ngAfterViewInit() 

if (this.menuComponent) 
{
    let click: MouseEvent = null;
    this.menuComponent.backdropClick.subscribe((event) => 
    {
        // the backdrop element is still in the DOM, so store the event for using after it has been detached
        click = event;
    });

    this.menuComponent.overlayDir.detach.subscribe((a) => 
    {
        if (click) 
        {
            let el = document.elementFromPoint(click.pageX, click.pageY) as HTMLElement;
            el.click();
        }
    });
} 
*/

}
