import { Component, OnInit, ViewChild } from '@angular/core';
import { ParticleService } from 'src/app/controller/particle.service';
import { DatabaseService } from 'src/app/controller/database.service';
import { InfluxService } from 'src/app/controller/influx.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export interface lectures {
  valor: number;
  fecha: string;
  hora: string;
}

export interface vars 
{
  sc: string;
  var: string;
  visibilidad: boolean;
}

export interface del {
  sc: string;
  var: string;
}

let ELEMENT_DATA: lectures[] = [];
let ELEMENT_DATA_VAR: vars[] = [];
let ELEMENT_DATA_DEL: del[] = [];

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})
export class DataComponent implements OnInit {
  devices = [];
  fbDevices;
  filteredDevices = [];
  usuario = null;
  ejemplo;
  displayedColumnsTable: string[] = ['valor', 'fecha', 'hora'];
  displayedColumnsGraph: string[] = ['sc', 'var', 'visualizar'];
  displayedColumnsDelete: string[] = ['sc', 'var', 'eliminar'];
  dataSourceTable = new MatTableDataSource(ELEMENT_DATA);
  dataSourceGraph = new MatTableDataSource(ELEMENT_DATA_VAR);
  dataSourceDelete = new MatTableDataSource(ELEMENT_DATA_DEL);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  selectedDevice: string = '';
  selectedDeviceChart: string = '';
  selectedVariable: string = '';
  selectedVariableChart: string = '';
  filteredVariables = [];
  filteredVariablesChart = [];
  vectorChart = [];
  loadingChart: boolean = false;
  deletingVariable = false;
  loadingTable: boolean = false;
  scSinVariables: boolean = false;
  scSinVariablesChart: boolean = false;
  addedVariablesChart = [];
  deviceName: string;
  variableName: string;
  tabla;



  constructor(public particle: ParticleService, public db: DatabaseService, public influx: InfluxService) 
  {
    
    this.db.getCurrentUser().subscribe(u => 
    {
      if (u) 
      {
        this.usuario = u;
        console.log(this.usuario);
      }
    });
  }

  ngOnInit() 
  {
    this.getData();
    this.dataSourceTable.sort = this.sort;
    this.dataSourceTable.paginator = this.paginator;
  }

  ngOnDestroy() 
  {
    ELEMENT_DATA = [];
    ELEMENT_DATA_VAR = [];
  }

  async getData() 
  {
    await this.delay(1000).then(data => this.getFbDevices())
  }

  getFbDevices() 
  {
    this.db.getCurrentUser().subscribe(user => 
    {
      this.fbDevices = user.devices;
      this.getParticleDevices();
    })
  }

  async getParticleDevices() 
  {
    this.devices = await this.particle.getDevices();

    this.fbDevices.forEach(fireId => 
      {
      this.devices.forEach(Particle => 
      {
        if (fireId === Particle.id) 
        {
          this.filteredDevices.push(Particle);
        }
      });
    });
    if (this.filteredDevices.length > 0) 
    {
      this.selectedDevice = this.filteredDevices[0].name;             //SucreCore tab Tabla
      this.selectedDeviceChart = this.filteredDevices[0].name;        //SucreCore tab Gráfica
      await this.getVariablesMainPage(this.selectedDevice);           //Obtenemos las mediciones del SucreCore del tab "Tabla"
      await this.getVariablesChartPage(this.selectedDeviceChart);     //Obtenemos las mediciones del SucreCore del tab "Gráfica"
      await this.getAllVariables();                                   //Obtenemos todas las mediciones para el tab "Eliminar"
    }
  }

  async delay(ms: number) 
  {
    return await new Promise(resolve => setTimeout(resolve, ms));
  }

  async getVariablesMainPage(scName) 
  {
    this.deviceName = scName;
    this.filteredVariables = [];
    this.selectedVariable = '';
    this.scSinVariables = false;
    this.loadingTable = true;

    ELEMENT_DATA = [];
    this.dataSourceTable = new MatTableDataSource(ELEMENT_DATA);
    this.dataSourceTable.paginator = null;
    this.filteredVariables = await this.getVariablesSucreCore(scName);

    if (this.filteredVariables.length > 0) 
    {
      this.selectedVariable = this.filteredVariables[0];
      await this.setTableData(this.selectedDevice, this.selectedVariable);   //Añadimos los valores a la tabla
    }
    else 
    {
      this.scSinVariables = true;
      this.loadingTable = false;
    }
    this.dataSourceTable.paginator = this.paginator;
  }

  async getVariablesChartPage(scName) 
  {
    this.filteredVariablesChart = [];
    this.selectedVariableChart = '';
    this.scSinVariablesChart = false;
    console.log(scName);
    this.filteredVariablesChart = await this.getVariablesSucreCore(scName);
    if (this.filteredVariablesChart.length > 0) { this.selectedVariableChart = this.filteredVariablesChart[0]; }
    else { this.scSinVariablesChart = true; }
  }

  async getVariablesSucreCore(scName) 
  {
    var varVector = [];
    this.influx.getVariables(scName).subscribe((variables => 
    {
      variables.forEach(variable => 
      {
        console.log(variable.variable);
        varVector.unshift(variable.variable);
      });
    }));
    await this.delay(1500);
    return varVector;
  }

  readValues(scName, varName) 
  {
    var vecDatos = [{ name: varName + "(" + scName + ")", series: [] }];
    this.influx.getValues(scName, varName).subscribe((data => 
    {
      data.forEach(lecture => 
      {
        var date = new Date(lecture.time);
        vecDatos[0].series.unshift({ value: lecture.value, name: date });
      });
    }));
    return vecDatos;
  }

  async setTableData(scName, varName) 
  {
    this.deviceName = scName;
    this.variableName = varName;
    this.loadingTable = true;
    console.log(varName);
    ELEMENT_DATA = [];
    var vecDatos = this.readValues(scName, varName);
    await this.delay(1000);
    vecDatos[0].series.forEach(lecture => 
    {
      var date = new Date(lecture.name);
      ELEMENT_DATA.push({ "valor": lecture.value, "fecha": date.toLocaleDateString(), "hora": date.toLocaleTimeString() });
    });
    this.dataSourceTable = new MatTableDataSource(ELEMENT_DATA);
    this.dataSourceTable.paginator = this.paginator;
    this.dataSourceTable.sort = this.sort;
    this.loadingTable = false;
  }

  async addVarToTable(scName, varName) 
  {
    this.loadingChart = true;
    var target = ELEMENT_DATA_VAR.find(set => set.sc == scName && set.var == varName);
    if (!target) 
    {
      var element = { "sc": scName, "var": varName, "visibilidad": true };
      ELEMENT_DATA_VAR.push(element);
      this.dataSourceGraph = new MatTableDataSource(ELEMENT_DATA_VAR);
      var vecDatos = this.readValues(element.sc, element.var);
      await this.delay(1000);
      var vecDatos2 = vecDatos.concat(this.vectorChart);
      this.vectorChart = vecDatos2;
      this.addedVariablesChart = vecDatos2;
    }
    this.loadingChart = false;
  }

  async anyadir_variable_a_tabla()
  {
    this.loadingChart = true;
    var target = ELEMENT_DATA_VAR.find(set => set.sc == this.deviceName && set.var == this.variableName);
    if (!target) 
    {
      var element = { "sc": this.deviceName, "var": this.variableName, "visibilidad": true };
      ELEMENT_DATA_VAR.push(element);
      this.dataSourceGraph = new MatTableDataSource(ELEMENT_DATA_VAR);
      var vecDatos = this.readValues(element.sc, element.var);
      await this.delay(1000);
      var vecDatos2 = vecDatos.concat(this.vectorChart);
      this.vectorChart = vecDatos2;
      this.addedVariablesChart = vecDatos2;
    }
    this.loadingChart = false;
  }

  async visibilityOn(element) 
  {
    this.loadingChart = true;
    var nombreAdd = element.var + '(' + element.sc + ')';
    var target = this.addedVariablesChart.find(set => set.name == '' + nombreAdd);
    if (target) {
      this.vectorChart = this.vectorChart.concat([target]);
    }
    /* else{
       var vecDatos = this.readValues(element.sc,element.var);
       await this.delay(1000);
       var vecDatos2 = (await vecDatos).concat(this.vectorChart);
       this.vectorChart = vecDatos2;
     }*/
    element.visibilidad = !element.visibilidad;
    this.loadingChart = false;
  }

  visibilityOff(element) 
  {
    this.loadingChart = true;
    var nombreElim = element.var + '(' + element.sc + ')';
    var target = this.vectorChart.find(set => set.name == '' + nombreElim);
    if (target) 
    {
      this.vectorChart = this.vectorChart.filter(function (item) { return item !== target });
    }
    element.visibilidad = !element.visibilidad;
    this.loadingChart = false;
  }

  async getAllVariables() 
  {
    ELEMENT_DATA_DEL = [];
    this.filteredDevices.forEach(device => 
    {
      this.influx.getVariables(device.name).subscribe((variables => 
      {
        variables.forEach(variable => 
        {
          ELEMENT_DATA_DEL.unshift({ "sc": device.name, "var": variable.var });
        });
      }));
    })

    await this.delay(2000);
    this.dataSourceDelete = new MatTableDataSource(ELEMENT_DATA_DEL);
    this.deletingVariable = false;
  }

  tableHasVariable(scName, varName) 
  {
    var targetTable = ELEMENT_DATA_VAR.find(set => set.sc == scName && set.var == varName);
    return targetTable;
  }

  tablaVariable()
  {
    var targetTable = ELEMENT_DATA_VAR.find(set => set.sc == this.deviceName && set.var == this.variableName);
    return targetTable;
  }

  async deleteVariable(element) 
  {
    if (confirm("Si confirma esta acción se borrará la variable " + element.sc + " del SucreCore " + element.var + "\n¿Desea continuar?")) {
      this.deletingVariable = true;

      //Borramos la variable de la base de datos
      this.influx.deleteVariable(element.sc, element.var).subscribe((data => console.log(data)));

      var nombreElim = element.var + '(' + element.sc + ')';

      var targetTable = ELEMENT_DATA_VAR.find(set => set.sc == element.sc && set.var == element.var);
      if (targetTable) 
      {

        //Borramos la variable de la gráfica
        var target = this.vectorChart.find(set => set.name == '' + nombreElim);
        this.vectorChart = this.vectorChart.filter(function (item) { return item !== target });

        //Borramos la variable del vector auxiliar
        this.addedVariablesChart = this.addedVariablesChart.filter(function (item) { return item !== target });
        console.log(this.addedVariablesChart);
        console.log(this.vectorChart);

        //Borramos la variable de la tabla del apartado "Gráfica"
        ELEMENT_DATA_VAR = ELEMENT_DATA_VAR.filter(function (item) { return item !== targetTable });
        this.dataSourceGraph = new MatTableDataSource(ELEMENT_DATA_VAR);
      }
      
      await this.delay(1000);
      if (element.sc === this.selectedDevice) { await this.getVariablesMainPage(this.selectedDevice); }
      if (element.sc === this.selectedDeviceChart) { await this.getVariablesChartPage(this.selectedDeviceChart); }
      await this.getAllVariables();
    }
  }

  descargarTablaPdf() 
  {
    // Extraemos el elemento que contiene la tabla
    const elementoTabla = document.getElementById('tablaDatos');
    const docTabla = new jsPDF('p', 'pt', 'a4');
    

    html2canvas(elementoTabla).then((canvas) => 
    {
      const imgagenCanvas = canvas.toDataURL('image/PNG');
      // Añadir imagen Canvas al pdf
      const imgProps = (docTabla as any).getImageProperties(imgagenCanvas);
      const pdfWidth = docTabla.internal.pageSize.getWidth() - 30;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      docTabla.addImage(imgagenCanvas, 'PNG', 15, 15, pdfWidth, pdfHeight, undefined, 'FAST');
      return docTabla;
    }).then(() => 
    {
      docTabla.save(this.deviceName+"_"+this.variableName+".pdf");
    });
  }

  descargarGraficaPdf() 
  {
    // Extraemos el elemento que contiene la gráfica
    const elementoGrafica = document.getElementById('datosGraficaPDF');
    const docGrafica = new jsPDF('p', 'pt', 'a4');
    const opcionesGrafica = 
    {
      background: 'white',
      scale: 3
    };

    html2canvas(elementoGrafica, opcionesGrafica).then((canvas) => 
    {
      const imgagenCanvas = canvas.toDataURL('image/PNG');
      // Añadir imagen Canvas al pdf
      const imgProps = (docGrafica as any).getImageProperties(imgagenCanvas);
      const pdfWidth = docGrafica.internal.pageSize.getWidth() - 30;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      docGrafica.addImage(imgagenCanvas, 'PNG', 15, 15, pdfWidth, pdfHeight, undefined, 'FAST');
      return docGrafica;
    }).then(() => 
    {
      docGrafica.save(this.deviceName+"_graficas.pdf");
    });
  }

  descargarTablaCSV() 
  {
    // Obtener el elemento HTML con el id de la tabla de la que queremos generar el csv
    const tablaMediciones = document.querySelector('#tablaMediciones');

    //Contenido primera fila csv.
    let stringCsv = "Valor;Fecha;Hora\n";
    var contador = 0;

    // Obtener todas las filas de la tabla
    const rows = tablaMediciones.querySelectorAll('td'); 
  
    //Recorremos los elementos de la tabla por filas
    for (var i = 0; i < rows.length; i++) 
    {
      //Rellenamos el doc, separando cada celda por ; e incrementamos el contador
      stringCsv += rows[i].textContent+";"; 
      contador ++;

      //La tabla solo tiene 3 columnas, si el contador llega a 3. Fin de fila. (Salto de línea en csv).
      if(contador == 3)
      {
        stringCsv += "\n";
        contador = 0;
      }
    }

    var filename = this.deviceName+"_"+this.variableName+".csv";
    this.descargarDocCSV(stringCsv, filename);
  }

  descargarDocCSV(csv, filename) 
  {
    //Crear archivo csv
    var csvFile = new Blob([csv], {type: "text/csv"});

    // Download link
    var downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = "none";

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();
  }

}
