import { Component, OnInit, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router"
import { DatabaseService } from 'src/app/controller/database.service';


@Component({
  selector: 'app-auhentication',
  templateUrl: './auhentication.component.html',
  styleUrls: ['./auhentication.component.css']
})
export class AuhenticationComponent {

  email: string;
  password: string;
  regemail: string;
  regpassword: string;
  regpasswordrep: string;
  error: boolean = false;
  errorReg: boolean = false;
  showRegister: boolean = false;
  mgs_user = '';
  msg_error = '';
  msg_error_reg = '';

  constructor(public afAuth: AngularFireAuth, private router: Router, private dbs: DatabaseService) { }
  ngOnInit() {
  }

  signIn() {
    console.log(this.email);
    this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password)
      .then(userCredential => {
        // Signed in
        var user = userCredential.user;
        this.email = '';
        this.password = '';
        this.router.navigate(['/dashboard'])
      })
      .catch(error => {
        var errorMessage = error.message;
        this.msg_error = errorMessage;
        this.error = true;
      });
  }

  signOut() {
    this.afAuth.auth.signOut();
  }

/*   resetPwd() {
    this.afAuth.auth.sendPasswordResetEmail(this.email).then(mgs => {
      // Email sent.
      this.mgs_user = 'Comprueba tu email para restablecer la contraseña.';
    }).catch(error => {
      // An error happened.
      var errorMessage = error.message;
      this.msg_error = errorMessage;
      this.error = true;
      this.mgs_user = '';
    });
  } */
}

