import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../controller/database.service';
import { Usuario } from '../../model/firestore-model';

@Component
({
    selector: 'app-userdetails',
    templateUrl: './userdetails.component.html',
    styleUrls: ['./userdetails.component.css']
})

export class UserdetailsComponent implements OnInit
{
    usuario = null;

    constructor(public db: DatabaseService) 
    {
        this.db.getCurrentUser().subscribe(u =>
        {
            if (u) 
            {
                this.usuario = u;
                console.log(this.usuario);
            }
        });
    }

    ngOnInit()
    {
    }

    guardarCambiosUser() 
    {
        this.db.updateUser(this.usuario.id_user, this.usuario.name, this.usuario.devices, this.usuario.centro, this.usuario.version_sucre);
    }

}
