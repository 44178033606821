import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

declare var Particle
var buf = require('buffer/').Buffer;

@Injectable({
  providedIn: 'root'
})
export class ParticleService {

  private devices: any[];
  private particle: any;
  private token: string;
  private appFiles;
  private nameFiles;
  private appFilesData = {};
  status;


  constructor(private http: HttpClient) {
    this.particle = new Particle();
    this.devices = [];
    this.login('sergi.trilles.oliver@gmail.com', 'sucre4kids');

    this.appFiles = [
      "assets/oled/Grove_OLED_128x64.h",
      "assets/oled/Grove_OLED_128x64.cpp",
      "assets/ultrasonic/Grove-Ultrasonic-Ranger.h",
      "assets/ultrasonic/Grove-Ultrasonic-Ranger.cpp",
      "assets/ledBar/Grove_LED_Bar.h",
      "assets/ledBar/Grove_LED_Bar.cpp",
      "assets/display/TM1637Display.h",
      "assets/display/TM1637Display.cpp",
      "assets/Particle_Adafruit_DHT/Adafruit_DHT_Particle.h",
      "assets/Particle_Adafruit_DHT/Adafruit_DHT_Particle.cpp",
      "assets/ledVariable/ChainableLED.h",
      "assets/ledVariable/ChainableLED.cpp",
      "assets/mqtt/MQTT.h",
      "assets/mqtt/MQTT.cpp",
      "assets/asyncrk/PublishQueueAsyncRK.h",
      "assets/asyncrk/PublishQueueAsyncRK.cpp",
      "assets/jsonparser/JsonParserGeneratorRK.h",
      "assets/jsonparser/JsonParserGeneratorRK.cpp",
      "assets/dnamehelper/DeviceNameHelperRK.h",
      "assets/dnamehelper/DeviceNameHelperRK.cpp",
      "assets/MotorDriver/MotorDriver.h",
      "assets/MotorDriver/MotorDriver.cpp",
      "assets/Adafruit_LEDBackpack_RK/Adafruit_LEDBackpack_RK.h",
      "assets/Adafruit_LEDBackpack_RK/Adafruit_LEDBackpack_RK.cpp",
      "assets/Adafruit_LEDBackpack_RK/Adafruit_LEDBackpack.h",
      "assets/Adafruit_GFX_RK/Adafruit_GFX.h",
      "assets/Adafruit_GFX_RK/Adafruit_GFX_RK.cpp",
      "assets/Adafruit_GFX_RK/Adafruit_GFX_RK.h",
      "assets/Adafruit_GFX_RK/Adafruit_GrayOLED.cpp",
      "assets/Adafruit_GFX_RK/Adafruit_GrayOLED.h",
      "assets/Adafruit_GFX_RK/Adafruit_SPITFT.cpp",
      "assets/Adafruit_GFX_RK/Adafruit_SPITFT.h",
      "assets/Adafruit_GFX_RK/Adafruit_SPITFT_Macros.h",
      "assets/Adafruit_GFX_RK/FreeMono9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMono12pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMono18pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMono24pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoBold9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoBold12pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoBold18pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoBold24pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoBoldOblique9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoBoldOblique12pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoBoldOblique18pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoBoldOblique24pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoOblique9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoOblique12pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoOblique18pt7b.h",
      "assets/Adafruit_GFX_RK/FreeMonoOblique24pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSans9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSans12pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSans18pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSans24pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansBold9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansBold12pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansBold18pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansBold24pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansBoldOblique9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansBoldOblique12pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansBoldOblique18pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansBoldOblique24pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansOblique9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansOblique12pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansOblique18pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSansOblique24pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerif9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerif12pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerif18pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerif24pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerifBold9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerifBold12pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerifBold18pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerifBold24pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerifBoldItalic9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerifBoldItalic12pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerifBoldItalic18pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerifBoldItalic24pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerifItalic9pt7b.h",
      "assets/Adafruit_GFX_RK/FreeSerifItalic12pt7b.h",
      "assets/Adafruit_GFX_RK/gfxfont.h",
      "assets/Adafruit_GFX_RK/glcdfont.c",
      "assets/Adafruit_GFX_RK/Org_01.h",
      "assets/Adafruit_GFX_RK/Picopixel.h",
      "assets/Adafruit_GFX_RK/Tiny3x3a2pt7b.h",
      "assets/Adafruit_GFX_RK/TomThumb.h",
      "assets/Adafruit_GFX_RK/Adafruit_BusIO_Register.cpp",
      "assets/Adafruit_GFX_RK/Adafruit_BusIO_Register.h",
      "assets/Adafruit_GFX_RK/Adafruit_I2CDevice.cpp",
      "assets/Adafruit_GFX_RK/Adafruit_I2CDevice.h",
      "assets/Adafruit_GFX_RK/Adafruit_I2CRegister.h",
      "assets/Adafruit_GFX_RK/Adafruit_SPIDevice.cpp",
      "assets/Adafruit_GFX_RK/Adafruit_SPIDevice.h"
    ];

    this.nameFiles = [
      "Grove_OLED_128x64.h",
      "Grove_OLED_128x64.cpp",
      "Grove-Ultrasonic-Ranger.h",
      "Grove-Ultrasonic-Ranger.cpp",
      "Grove_LED_Bar.h",
      "Grove_LED_Bar.cpp",
      "TM1637Display.h",
      "TM1637Display.cpp",
      "Adafruit_DHT_Particle.h",
      "Adafruit_DHT_Particle.cpp",
      "ChainableLED.h",
      "ChainableLED.cpp",
      "MQTT.h",
      "MQTT.cpp",
      "PublishQueueAsyncRK.h",
      "PublishQueueAsyncRK.cpp",
      "JsonParserGeneratorRK.h",
      "JsonParserGeneratorRK.cpp",
      "DeviceNameHelperRK.h",
      "DeviceNameHelperRK.cpp",
      "MotorDriver.h",
      "MotorDriver.cpp",
      "Adafruit_LEDBackpack_RK.h",
      "Adafruit_LEDBackpack_RK.cpp",
      "Adafruit_LEDBackpack.h",
      "Adafruit_GFX.h",
      "Adafruit_GFX_RK.cpp",
      "Adafruit_GFX_RK.h",
      "Adafruit_GrayOLED.cpp",
      "Adafruit_GrayOLED.h",
      "Adafruit_SPITFT.cpp",
      "Adafruit_SPITFT.h",
      "Adafruit_SPITFT_Macros.h",
      "FreeMono9pt7b.h",
      "FreeMono12pt7b.h",
      "FreeMono18pt7b.h",
      "FreeMono24pt7b.h",
      "FreeMonoBold9pt7b.h",
      "FreeMonoBold12pt7b.h",
      "FreeMonoBold18pt7b.h",
      "FreeMonoBold24pt7b.h",
      "FreeMonoBoldOblique9pt7b.h",
      "FreeMonoBoldOblique12pt7b.h",
      "FreeMonoBoldOblique18pt7b.h",
      "FreeMonoBoldOblique24pt7b.h",
      "FreeMonoOblique9pt7b.h",
      "FreeMonoOblique12pt7b.h",
      "FreeMonoOblique18pt7b.h",
      "FreeMonoOblique24pt7b.h",
      "FreeSans9pt7b.h",
      "FreeSans12pt7b.h",
      "FreeSans18pt7b.h",
      "FreeSans24pt7b.h",
      "FreeSansBold9pt7b.h",
      "FreeSansBold12pt7b.h",
      "FreeSansBold18pt7b.h",
      "FreeSansBold24pt7b.h",
      "FreeSansBoldOblique9pt7b.h",
      "FreeSansBoldOblique12pt7b.h",
      "FreeSansBoldOblique18pt7b.h",
      "FreeSansBoldOblique24pt7b.h",
      "FreeSansOblique9pt7b.h",
      "FreeSansOblique12pt7b.h",
      "FreeSansOblique18pt7b.h",
      "FreeSansOblique24pt7b.h",
      "FreeSerif9pt7b.h",
      "FreeSerif12pt7b.h",
      "FreeSerif18pt7b.h",
      "FreeSerif24pt7b.h",
      "FreeSerifBold9pt7b.h",
      "FreeSerifBold12pt7b.h",
      "FreeSerifBold18pt7b.h",
      "FreeSerifBold24pt7b.h",
      "FreeSerifBoldItalic9pt7b.h",
      "FreeSerifBoldItalic12pt7b.h",
      "FreeSerifBoldItalic18pt7b.h",
      "FreeSerifBoldItalic24pt7b.h",
      "FreeSerifItalic9pt7b.h",
      "FreeSerifItalic12pt7b.h",
      "gfxfont.h",
      "glcdfont.c",
      "Org_01.h",
      "Picopixel.h",
      "Tiny3x3a2pt7b.h",
      "TomThumb.h",
      "Adafruit_BusIO_Register.cpp",
      "Adafruit_BusIO_Register.h",
      "Adafruit_I2CDevice.cpp",
      "Adafruit_I2CDevice.h",
      "Adafruit_I2CRegister.h",
      "Adafruit_SPIDevice.cpp",
      "Adafruit_SPIDevice.h"
    ];

    let i = 0;
    const filePromises = this.appFiles.map((f, i) => {
      return this.http.get(f, { responseType: "blob" }).toPromise()
        .then(data => {
          this.appFilesData[this.nameFiles[i]] = data;
          console.log('nameFile', this.nameFiles[i]);
          console.log('data', data);
        })
        .catch(error => {
          console.error(`Error loading file ${f}: ${error}`);
        });
    });
    
  }

  async getDevicesPromise() {
    try {
        const deviceList = await this.particle.listDevices({ auth: this.token });
        this.devices = deviceList.body;
        return this.devices;
    } catch (error) {
        console.error(`Error al obtener dispositivos Particle: ${error.message}`, error);
        throw new Error(`Error al obtener dispositivos Particle: ${error.message}`);
    }
}

  async login(username: string, password: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      if (!this.token) {
        this.particle.login({ username, password })
          .then((data) => {
            this.token = data.body.access_token;
            console.log('token obtenido');
            this.particle.listAccessTokens({ username, password })
              .then((data) => {
                console.log('data on listing access tokens: ', data);
              }, (err) => {
                console.log('error on listing access tokens: ', err);
              });
            this.getDevicesPromise();
          })
          .catch((err => {
            console.error('no se pudo obtener el token');
            console.log(err)
          }));
      } else {
        console.log('ya hay token' + this.token);
        this.particle.listAccessTokens({ username, password })
          .then((data) => {
            console.log('data on listing access tokens: ', data);
          }, (err) => {
            console.log('error on listing access tokens: ', err);
          });
      }
    });
  }

  isSignedIn(): boolean {
    return this.token ? true : false;
  }

/*   myDevices() {
    return Promise.resolve(this.devices);
  } */

  getDevices() {
    return this.devices;
  }

  private handleError(error: HttpErrorResponse) {
    console.error('Error in Particle service:', error);
    return throwError(error);
  }

  async flashCode(code: string, device: string) {
    console.log('id de dispositivo', device);
    console.log('código a subir', code);
  
    // Convertir el código en un buffer
    let buf = Buffer.from(code);
    let firmwareBlob2 = new Blob([buf], { type: 'text/plain' });
  
    // Extraer los nombres de las librerías desde las líneas `#include`
    const includePattern = /#include\s+["<](.+?)\.h[">]/g;
    const includedLibraries = new Set<string>();
    let match;
    while ((match = includePattern.exec(code)) !== null) {
      includedLibraries.add(match[1]);
    }
    console.log('Librerías incluidas en el código:', Array.from(includedLibraries));
  
    // Filtrar archivos que pertenezcan a carpetas cuyo nombre coincide con el include
    const filteredAppFiles = this.appFiles.filter(filePath => {
      const folderName = filePath.split('/').slice(-2, -1)[0]; // Obtener el nombre de la carpeta
      return includedLibraries.has(folderName); // Comprobar si coincide con algún include
    });
  
    console.log('Archivos seleccionados para cargar:', filteredAppFiles);
  
    // Descargar solo los archivos filtrados y guardarlos en `appFilesData`
    const filePromises = filteredAppFiles.map(f => {
      const fileName = f.split('/').pop(); // Obtener solo el nombre del archivo
      return this.http.get(f, { responseType: "blob" }).toPromise()
        .then(data => {
          this.appFilesData[fileName] = data;
          console.log('Archivo cargado:', fileName);
        })
        .catch(error => {
          console.error(`Error cargando archivo ${f}: ${error}`);
        });
    });
  
    await Promise.all(filePromises);
  
    // Preparar los archivos para enviar a Particle
    let files = {};
    for (const f in this.appFilesData) {
      files[f] = new Blob([this.appFilesData[f]], { type: "text/plain" });
    }
  
    files["main.ino"] = firmwareBlob2;
    console.log('Archivo principal cargado:', files["main.ino"]);
  
    return new Promise((resolve, reject) => {
      let opts = {
        deviceId: device,
        files: files,
        auth: this.token,
      };
  
      this.particle.flashDevice(opts)
        .then((result) => {
          const body = result.body;
          console.log('Resultado de la actualización:', body);
  
          if (body.ok) {
            setTimeout(() => {
              console.log("SucreCore actualizado");
              resolve("SucreCore actualizado");
            }, 2000);
          } else {
            console.log("Error al actualizar:", body.errors.join("\n"));
            reject("Error al actualizar");
          }
        })
        .catch((err) => {
          if (err.message === "Timeout") {
            reject("Timeout intentando actualizar. ¿Está la placa conectada?");
            return;
          }
          reject(err);
        });
    });
  }
  

  /*
  actual
  async flashCode(code: string, device: string) {
    console.log('id de dispositivo', device);
    console.log('código a subir', code);
  
    // Convertir el código en un buffer
    let buf = Buffer.from(code);
    console.log(buf);
    let firmwareBlob2 = new Blob([buf], { type: 'text/plain' });
    
    // Convertir el código en texto para analizar los includes
    const codeText = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsText(firmwareBlob2);
    });
  
    // Extraer los nombres de las librerías de las líneas con #include
    const includePattern = /#include\s+["<](.+?)\.h[">]/g;
    const includedLibraries = new Set<string>();
    let match;
    while ((match = includePattern.exec(codeText)) !== null) {
      includedLibraries.add(match[1]);
    }
    console.log('Librerías incluidas en el código:', Array.from(includedLibraries));
  
    // Filtrar los archivos de appFiles que están en los includes
    // Filtrar los archivos de appFiles que coincidan con los includes del código
    const filteredAppFiles = this.appFiles.filter(filePath => {
      const fileName = filePath.split('/').pop(); // Obtiene el nombre del archivo completo
      const fileBaseName = fileName ? fileName.split('.').shift() : null; // Elimina la extensión
      return fileBaseName && includedLibraries.has(fileBaseName); // Verifica si coincide con las librerías incluidas
    });

    // Descargar solo los archivos filtrados y guardarlos en appFilesData
    const filePromises = filteredAppFiles.map((f, i) => {
      const fileName = f.split('/').pop();
      return this.http.get(f, { responseType: "blob" }).toPromise()
        .then(data => {
          this.appFilesData[fileName] = data;
          console.log('Archivo cargado:', fileName);
        })
        .catch(error => {
          console.error(`Error cargando archivo ${f}: ${error}`);
        });
    });

    await Promise.all(filePromises);
  
    // Preparar los archivos para enviar a Particle
    let files = {};
    for (const f in this.appFilesData) {
      files[f] = new Blob([this.appFilesData[f]], { type: "text/plain" });
    }
  
    files["main.ino"] = firmwareBlob2;
    console.log(files["main.ino"]);
  
    return new Promise(async (resolve, reject) => {
      let opts = {
        deviceId: device,
        files: files,
        auth: this.token
      };
  
      this.particle.flashDevice(opts)
        .then((result) => {
          var body = result.body;
          console.log('Resultado de la actualización:', body);
  
          if (body.ok) {
            setTimeout(function () {
              console.log("SucreCore actualizado");
              resolve("SucreCore actualizado");
            }, 2000);
          } else {
            console.log("Error al actualizar ", body.errors.join("\n"));
            reject("Error al actualizar");
          }
        }, (err) => {
          if (err.message == "Timeout") {
            reject("Timeout intentando actualizar. ¿Está la placa conectada?");
            return;
          }
          reject(err);
        });
    });
  }
  */
  /*
  async flashCode(code: string, device: string) {
    console.log('id de dispositivo', device);
    console.log('código a subir', code);
  
    let buf = Buffer.from(code);
    console.log(buf)
    let firmwareBlob2 = new Blob([buf], { type: 'text/plain' });
    let files = {};
  
    const filePromises = this.appFiles.map((f, i) => {
      return this.http.get(f, { responseType: "blob" }).toPromise()
        .then(data => {
          this.appFilesData[this.nameFiles[i]] = data;
        })
        .catch(error => {
          console.error(`Error loading file ${f}: ${error}`);
        });
    });
    
    await Promise.all(filePromises);
    
    for (const f in this.appFilesData) {
      files[f] = new Blob([this.appFilesData[f]], { type: "text/plain" });
    };
  
    files["main.ino"] = firmwareBlob2;
    console.log(files["main.ino"])
  
    return new Promise(async (resolve, reject) => {
      let opts = {
        deviceId: device,
        files: files,
        auth: this.token
      };
  
      this.particle.flashDevice(opts)
        .then((result) => {
          var body = result.body;
  
          if (body.ok) {
            setTimeout(function () {
              console.log("SucreCore actualizado");
              resolve("SucreCore actualizado")
            }, 2000);
          } else {
            console.log("Error al actualizar ", body.errors.join("\n"));
            reject("Error al actualizar");
          }
        }, (err) => {
          if (err.message == "Timeout") {
            reject("Timeout intentando actualizar. ¿Está la placa conectada?");
            return;
          }
          reject(err);
        });
    });
  }
  
  
  
    async flashCode(code: string, device: string) {
      console.log('id de dispositivo', device);
      console.log('código a subir', code);
  
      let buf = Buffer.from(code);
      console.log(buf)
      let firmwareBlob2 = new Blob([buf], { type: 'text/plain' });
      let files = {};
  
      //cargando librerias
      for (var f in this.appFilesData) {
        files[f] = new Blob([this.appFilesData[f]], { type: "text/plain" });
      };
  
      files["main.ino"] = firmwareBlob2;
      console.log(files["main.ino"])
      return await new Promise(async (resolve, reject) => {
        let opts = {
          deviceId: device,
          files: files,
          auth: this.token
        };
  
        return await this.particle.flashDevice(opts)
          .then((result) => {
            if (!result.body.ok) {
              console.log("1", "Error al actualizar");
              resolve("Placa apagada")
            }
            else {
              if (result.body.message == "timed out waiting for device to start") {
                console.log("2", "Error al actualizar");
                resolve("Error al actualizar")
  
              }
              else {
                console.log("Se está actualizando");
                (async () => {
                  await this.delay(10000).then(data => {
                    console.log("Placa actualizada");
                    resolve("Placa actualizada")
  
                  }
                  )
                });
              }
  
            }
            console.log(result)
          }).catch((err) => {
            resolve("Error al actualizar")
            console.error(err);
  
          });
      })
    }
    */


  async delay(ms: number) {
    return await new Promise(resolve => setTimeout(resolve, ms));
  }

}
