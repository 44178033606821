import { Component, OnInit, HostListener } from '@angular/core';
import { DatabaseService } from '../../controller/database.service'
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnalyticsService } from 'src/app/controller/analytics.service';

@Component(
{
    selector: 'app-newproject',
    templateUrl: './newproject.component.html',
    styleUrls: ['./newproject.component.css']
})
export class NewprojectComponent implements OnInit 
{

    detailName = '';
    detailDescription = '';
    detailSucre;
    detailProjectType = '';
    firstFormGroup: FormGroup;
    projectTypes: any[] = [
        {value: 'Basica', display: 'Básica'},
        {value: 'Avanzada', display: 'Avanzada'}
      ];       

    constructor(public db: DatabaseService, private router: Router,
    private analytics: AnalyticsService, private _formBuilder: FormBuilder) { }


    @HostListener('document:keydown.enter', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.createProject();
    }

    ngOnInit() 
    {
        this.firstFormGroup = this._formBuilder.group(
        {
            detailName: ['', Validators.required],
            detailDescription: ['', Validators.required],
            detailProjectType: ['', Validators.required]
        });
        this.detailProjectType = this.db.userDB.version_sucre;

    }

    createProject() 
    {
        console.log("creating project");
        if (this.detailName != '' || this.detailProjectType !='') 
        {
                console.log("creating project");
                localStorage.setItem('name', this.detailName)
                //this.detailProjectType = this.userDB.version_sucre;
                //localStorage.setItem('versionSucre', this.detailSucre) 
                localStorage.setItem('description', this.detailDescription)
                let idProj = this.db.createNewProject(this.detailProjectType);
                this.analytics.logEvent('new_project', { idproj: idProj, name: this.detailName, userid: this.db.userDB.email });
                this.router.navigate(['/project', idProj]);
        } 
        else 
        {
        

            // this.createError = 'Tienes que escribir un nombre para continuar';
        }
    }
}
