import { Component, OnInit,  ChangeDetectorRef, ChangeDetectionStrategy  } from '@angular/core';
import { ParticleService } from '../../controller/particle.service';
import { DatabaseService } from '../../controller/database.service';
import { Usuario } from '../../model/firestore-model';
import { BluetoothService } from '../../controller/bluetooth.service';
import { AccessPoint } from '../../model/accessPoint';




@Component
({
    selector: 'app-menuusu',
    templateUrl: './menuusu.component.html',
    styleUrls: ['./menuusu.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush

})

export class MenuusuComponent implements OnInit 
{
    core: boolean = false;

    devices = [];
    BluSer: BluetoothService = new BluetoothService();
    PAGE_SIZE = 5;
    page = 0;   
    fbDevices;
    filteredDevices = [];
    selectedDevice: string;
    selectedCentro: string;
    centros = null;
    usuario = null;
    wifiList: any;
    selectedAP: AccessPoint;
    wifiPassword: string;
    refreshWifi: boolean;
    connectedWifiName: String;
    bleFilters = {};
    defaultDevice: string;
    nameDeviceSelected: string;
    selectedDevice2;

    constructor(public particle: ParticleService, public db: DatabaseService, private cdr: ChangeDetectorRef) 
    {
        this.db.subscribeToCentros().subscribe(c => 
        {
            this.centros = c;

            this.db.getCurrentUser().subscribe(u => 
            {
                if (u) 
                {
                  this.usuario = u;
                  this.fbDevices = u.devices;
                  
                }
            });
        });

        this.getData();
    }


    async getData()
    {
      await this.delay(500);
      await this.getFbDevices();
    }


    getFbDevices() 
    {
        this.db.getCurrentUser().subscribe(user => 
        {
            this.fbDevices = user.devices;
            this.getDevicesWithRetry();
        })
    }

    changePage(newPage: number) {
        this.page = newPage;
      }

    ngOnInit() 
    {
        this.defaultDevice = null;
        this.getDevicesWithRetry();
        //this.getDevicesWithRetry();
    //  this.getFbDevices();
    }

  /*   async getData() {
      await this.delay(1000).then(data => this.getFbDevices())
    }
   */
  /*   getFbDevices() {
      this.db.getCurrentUser().subscribe(user => {
        this.fbDevices = user.devices;
        this.getParticleDevices();
      })
    } */

    setDefault(device) {
        this.defaultDevice = device;
      }

      selectDevice(device) {
        this.selectedDevice = device.name;
        //this.getParticleDevices();
        this.guardarCambiosDevs();
      }

    guardarCambiosDevs() 
    {
        let devsToAdd = this.usuario.devices;
        if (this.filteredDevices) {
        this.selectedDevice2 = this.filteredDevices.find(device => {
            return device.name === this.selectedDevice;
          });
        }

        this.nameDeviceSelected = this.selectedDevice2.name;


        this.usuario.devices = [this.selectedDevice2.id];

        // first device is the current selected one
        devsToAdd.forEach(d => 
        {
            if (this.usuario.devices.indexOf(d) == -1) 
            {
                this.usuario.devices.push(d);
            }
        });

        this.db.updateUser(this.usuario.id_user, this.usuario.name, this.usuario.devices, this.usuario.centro, this.usuario.version_sucre);
    }

    guardarCambiosCentro() 
    {
        this.db.updateUser(this.usuario.id_user, this.usuario.name, this.usuario.devices, this.usuario.centro, this.usuario.version_sucre);
    }

    async delay(ms: number) 
    {
        return await new Promise(resolve => setTimeout(resolve, ms));
    }

    async getDevicesWithRetry(retries = 5, delay = 1000) {
        console.log("getDevicesWithRetry");
        for (let i = 0; i < retries; i++) {
          try {
            return await this.getParticleDevices();
          } catch (err) {
            console.error(`Attempt ${i + 1} failed. Retrying in ${delay}ms...`);
            await new Promise(res => setTimeout(res, delay));
            delay *= 2; // Increase delay for next retry
          }
        }
        throw new Error('All attempts failed');
      }

    async getParticleDevices() 
    {

        this.particle.getDevicesPromise().then((devs: []) => {
            this.filteredDevices = [];
      
            let newFilteredDevs = this.filteredDevices.slice(0);
            this.devices = devs;
            if (this.devices.length > 0) {
                this.fbDevices.forEach(fireId => 
                    {
                        this.devices.forEach(Particle => {
                            
                            if (fireId === Particle.id)  {
                                newFilteredDevs.push(Particle);
                        }
                    });
                });
              this.filteredDevices = newFilteredDevs;
      
              this.core = true;
              if (this.filteredDevices.length > 0) {
                this.selectedDevice = this.usuario.devices[0];
                this.selectedDevice2 = this.filteredDevices.find(device => device.id === this.selectedDevice);
                this.nameDeviceSelected = this.selectedDevice2.name;
              }
              this.filteredDevices = [...this.filteredDevices];
              this.filteredDevices = this.filteredDevices.sort((a, b) => new Date(b.last_heard).getTime() - new Date(a.last_heard).getTime());
              this.cdr.markForCheck();
            }
          }),
            (err) => console.log(err);
      

        /*
        console.log("getParticleDevices");
        this.devices = this.particle.getDevices();
        console.log(this.devices);
        console.log("fbDevices", this.fbDevices);
        this.filteredDevices = [];
        this.fbDevices.forEach(fireId => 
        {
            this.devices.forEach(Particle => 
            {   
                if (fireId === Particle.id) 
                {
                    this.filteredDevices.push(Particle)
                }
            });
        });
        console.log("filteredDevices2", this.filteredDevices);
        this.core = true;
        if (this.filteredDevices.length > 0) 
        {
            this.selectedDevice = this.usuario.devices[0];
            console.log("selectedDevice", this.selectedDevice);
            console.log("usuario", this.filteredDevices);
            this.selectedDevice2 = this.filteredDevices.find(device => device.id === this.selectedDevice);
            console.log("selectedDevice2", this.selectedDevice2);
            this.nameDeviceSelected = this.selectedDevice2.name;
        }
        this.filteredDevices = [...this.filteredDevices];
        console.log("antes ordenar", this.filteredDevices);
        this.filteredDevices = this.filteredDevices.sort((a, b) => new Date(b.last_heard).getTime() - new Date(a.last_heard).getTime());
        console.log("despues ordenar", this.filteredDevices);
        this.cdr.markForCheck();
        */
    }

    async connect() 
    {
        this.bleFilters = 
        {
            optionalServices: ['5c1b9a0d-b5be-4a40-8f7a-66b36d0a5176'] // Required to access service later.
        }
        if(this.filteredDevices.length > 1)
        {
            this.bleFilters["filters"] = [{namePrefix: "SC"}];
        }
        else
        {
            this.bleFilters["filters"] = [{name: this.filteredDevices[0].name}];
        }
        console.log(this.bleFilters);
        this.BluSer.connect(this.bleFilters);
    }

    //Recibimos la lista de wifis y clasificamos los datos
    setWiFiList() 
    {
        this.BluSer.wifiListReceiver();
        var aps = this.BluSer.getWifiList().slice(1, -1).split(",");

        var unfiltered = new Array(aps.length);

        for (let i = 0; i < aps.length; i++) 
        {
            unfiltered[i] = new AccessPoint(aps[i].slice(1, aps[i].indexOf(":") - 1),  //ssid
            Number(aps[i].slice(aps[i].indexOf(":") + 2, aps[i].indexOf(";"))),      //intensidad
            aps[i].slice(aps[i].indexOf(";") + 1, -1));                              //seguridad
        }
        this.wifiList = unfiltered.filter(function (value, index, arr) 
        {
            return value.ssid != "";
        })
        this.selectedAP = new AccessPoint(this.wifiList[0].ssid, this.wifiList[0].intensity, this.wifiList[0].security);
    }

    configWiFi() 
    {
        var ssidSent = this.selectedAP.ssid;
        var secSent = this.selectedAP.security;
        if (this.wifiPassword === undefined) this.wifiPassword = "";
        this.BluSer.connectWifi(ssidSent, secSent, this.wifiPassword);
    }

    compareNames(ap1: AccessPoint, ap2: AccessPoint): boolean 
    {
        return ap1 && ap2 ? ap1.ssid === ap2.ssid : ap1 === ap2;
    }

    apIsPublic() 
    {
        return this.selectedAP !== undefined && this.selectedAP.security === "0";
    }

    pwdNeeded() 
    {
        if (this.apIsPublic()) 
        {
            return false;
        }

        return this.wifiPassword === undefined || this.wifiPassword === "";
    }

    disconnect() 
    {
        this.BluSer.disconnect();
    }

    deleteWifi() 
    {
        this.BluSer.deleteWifi();
    }

    isConnected() 
    {
    
    return this.BluSer.isConnected();
    }

    isConnectingBLE() 
    {
        return this.BluSer.isConnectingBLE();
    }

    isRefreshingWifi() 
    {
        return this.BluSer.isRefreshingWifiList();
    }

    getConnectedResult() 
    {
        return this.BluSer.getConnectionResult();
    }

    hasWifi() 
    {
        this.connectedWifiName = this.BluSer.getWifiName();
        return this.BluSer.hasWifi();
    }

    isDeletingWifi() 
    {
        return this.BluSer.isDeletingWifi();
    }

    bleDisconnect()
    {
        return this.BluSer.bleDisconnect();
    }
}
