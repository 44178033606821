import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DatabaseService } from '../controller/database.service'

@Injectable
({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate 
{
  constructor(public db: DatabaseService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
    {

    return new Promise((resolve) => 
    {

      this.db.subscribeToAdmins().subscribe(admins => 
      {
        if (admins.user_id.includes(this.db.user.uid)) 
        {
          resolve(true);
        } 
        else 
        {
          this.router.navigate(['/dashboard']);
          resolve(false);
        }
      });
    });
  }
}
