import { Component, OnInit, OnDestroy, Inject, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { Observable, Subject, pipe } from 'rxjs';
import { tap, map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DatabaseService } from 'src/app/controller/database.service'
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { AnalyticsService } from 'src/app/controller/analytics.service';
export interface DialogData
{
    nombre: string;
    descripcion: string;
}

@Component
({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
})


export class DashboardComponent implements OnInit
{

    myControl = new FormControl();
    options = ['New', 'Física', 'Clase 2'];
    filteredOptions: Observable<string[]>;

    constructor(public db: DatabaseService, private router: Router, private storage: AngularFireStorage, private _snackBar: MatSnackBar, private analytics: AnalyticsService, public dialog: MatDialog)
    {
        this.load$ = false;
    }

    projectos;
    projectSubs;
    detailName = '';
    detailDescription = '';
    createError = '';
    load$ = false;
    centro = null;
    centro_id = '';
    centro_name = '';
    edintingName = '';
    edintingDesc = '';
    edintingTags = [];
    edintingVisible = '';
    copia_projectos;
    projectos_ordenados;
    editingProject = null;
    editingIndex = -1;
    userNames = new Map<string, string>();


    downloadURL: Observable<string>;

    ngOnInit() 
    {
        this.projects();
        this.filteredOptions = this.myControl.valueChanges.pipe(startWith(''),map(value => this._filter(value)),);


        }
    


    private _filter(value: string): string[] 
    {
        const filterValue = value.toLowerCase();

        return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }

    ngOnDestroy() 
    {
        this.projectSubs.unsubscribe();
    }

    store(name) 
    {
        localStorage.setItem('name', name);
    }


    duplicate(proj) 
    {
        let idProj = this.db.duplicateProject(proj);
        localStorage.setItem('name', 'Copia de ' + proj.project_name);
        this.router.navigate(['/project', idProj]);
    }
    
    getUserName(user_id: string): void {
        let usuSubs = this.db.getUsuarioById(user_id).subscribe(u => {
          if (u && u.name) {
            this.userNames.set(user_id, u.name);
          }
          usuSubs.unsubscribe();
        });
      }


    projects(): void 
    {
        this.projectSubs = this.db.getUserProjects().subscribe(proj => 
        {
            this.projectos = proj;
            console.log(this.projectos);
            for (let project of this.projectos) {
                this.getUserName(project.id_user);
              }
            if (this.db.userDB && this.db.userDB.centro) 
            {
                let centroSubs = this.db.getCentroById(this.db.userDB.centro).subscribe(c => 
                {
                    if (c) 
                    {
                        this.centro = c;
                        this.centro_name = c.nombre;
                        this.centro_id = c._id;
                        centroSubs.unsubscribe();
                        // Añadimos los proyectos del mismo centro pero de otros usuarios
                        this.db.getUserCentroProjects(c._id).subscribe(projC => 
                        {
                            this.projectos = proj;
                            for (let project of projC) {
                                this.getUserName(project.id_user);
                              }
                            projC.forEach(pr => 
                            {
                                if (pr.id_user !== this.db.userDB.id_user) 
                                {
                                    if (pr.centro !== '' && pr.centro !== null && !pr.centro_nombre) 
                                    {
                                        let centroSubs = this.db.getCentroById(pr.centro).subscribe(c => 
                                        {
                                            pr.centro_nombre = c.nombre;
                                            centroSubs.unsubscribe();
                                        });
                                    }

                                    this.projectos.push(pr);
                                    this.projectos_ordenados = this.db.getProyectosOrdenados(this.projectos);
                                    let usuSubs = this.db.getUsuarioById(pr.id_user).subscribe(u => 
                                    {
                                    if (u && u.name) 
                                    {
                                        pr.user_name = u.name;
                                    }
                                    usuSubs.unsubscribe();
                                });
                            }
                        });
                        this.load$ = true;
                    });
                }
            })
        } 
        else 
        {
            this.load$ = true;
        }

            // Recoger nombres de los centros de todos los proyectos del dashboard
            this.projectos.forEach(pr => 
            {
                if (pr.centro !== '' && pr.centro !== null && !pr.centro_nombre) 
                {
                    let centroSubs = this.db.getCentroById(pr.centro).subscribe(c => 
                    {
                        pr.centro_nombre = c.nombre;
                        centroSubs.unsubscribe();
                    });
                }
                else
                {
                    pr.centro_nombre = '';
                }
            });
        });
    }


    delete(ref, i): void
    {
        let snackBarRef = this._snackBar.open('¿Quieres eliminar el proyecto?', 'Si', 
        {
            duration: 2000,
        });

        snackBarRef.onAction().subscribe(() =>
        {
            this.db.deleteProject(ref);
            this.analytics.logEvent('delete_project', { idproj: ref, iduser: this.db.userDB.email });
            this.projectos.splice(this.findProjectById(this.projectos_ordenados[i]._id), 1);
        });
    }

    checkUserExists() 
    {
        //if the user is not saved in the bbdd, save it.
        this.db.getCurrentUser().subscribe(user => 
        {
            if (user == null) 
            {
                this.db.createNewUser();
            }
        })
    }

    findProjectById(id: string): number {
        for (let i = 0; i < this.projectos.length; i++) {
          if (this.projectos[i]._id === id) {
            return i;
          }
        }
        return -1; // Retorna -1 si no se encuentra el proyecto
      }

    saveProjectDetails() 
    {
        if (this.edintingName != '') 
        {
            let i = this.editingIndex;
            this.projectos[this.findProjectById(this.projectos_ordenados[i]._id)].project_name = this.edintingName;
            this.projectos[this.findProjectById(this.projectos_ordenados[i]._id)].project_description = this.edintingDesc;
            let cen = this.projectos[this.findProjectById(this.projectos_ordenados[i]._id)].centro ? this.projectos[this.findProjectById(this.projectos_ordenados[i]._id)].centro : '';
            console.log('cen',this.edintingName, this.projectos[this.findProjectById(this.projectos_ordenados[i]._id)]._id);
            this.db.updateProject(this.edintingName, this.projectos[this.findProjectById(this.projectos_ordenados[i]._id)]._id, this.projectos[this.findProjectById(this.projectos_ordenados[i]._id)].xml, cen, this.edintingDesc);
            this.editingProject = null;
            this.editingIndex = -1;
        }
        else 
        {
            this.createError = 'Tienes que escribir un nombre para guardar';
        }
    }

    editProjectDetailsDialog(project, i) 
    {

        this.editingProject = project;
        this.editingIndex = i;
        this.edintingName = this.editingProject.project_name;
        this.edintingDesc = this.editingProject.project_description;
 

        const dialogRef = this.dialog.open(DialogModifyDetails, 
        {
            data: 
            { 
                name: this.edintingName, desc: this.edintingDesc
            },
        });

        dialogRef.afterClosed().subscribe(result => 
        {
            if (result && result.name) 
            {
                this.edintingDesc = result.desc;
                this.edintingName = result.name;
                this.saveProjectDetails();
            }
        });
    }


    editTagsDialog(project, i) 
    {
        if (!project.tags) 
        {
            project.tags = [];
        }
        this.edintingTags = project.tags ? project.tags : [];

        const dialogRef = this.dialog.open(DialogModifyTags, 
        {
            data: 
            { 
                centrotags: this.centro.tags, projecttags: project.tags
            },
        });

        dialogRef.afterClosed().subscribe(result => 
        {
          if (result && result.projecttags) 
          {
            this.edintingTags = result.projecttags;
            this.db.updateProjectTags(project._id, this.edintingTags);
          }
        });
    }


    onFileInput(i, event) 
    {
        const file = event.target.files[0];
        const filePath = this.projectos[this.findProjectById(this.projectos_ordenados[i]._id)]._id;
        const ref = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, file).then(snap => 
        {
            snap.ref.getDownloadURL().then(url => 
            {
                this.projectos[this.findProjectById(this.projectos_ordenados[i]._id)].url_img = url;
                this.db.updateProjectImage(this.projectos[this.findProjectById(this.projectos_ordenados[i]._id)]._id, url);
            });
        });
    }
}

@Component(
{
    selector: 'dialog-modify-details',
    templateUrl: 'dialog-modify-details.html',
})

export class DialogModifyDetails 
{
    constructor(
    public dialogRef: MatDialogRef<DialogModifyDetails>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) { }

    onNoClick(): void 
    {
        this.dialogRef.close();
    }
}

@Component(
{
    selector: 'dialog-modify-tags',
    templateUrl: 'dialog-modify-tags.html',
})

export class DialogModifyTags 
{
    separatorKeysCodes: number[] = [ENTER, COMMA];
    tagCtrl = new FormControl();
    filteredTags: Observable<string[]>;
    tags: string[] = [];
    centrotags: string[];
    tagInput: any;

    constructor(public dialogRef: MatDialogRef<DialogModifyTags>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {  // DialogData throws error
        this.centrotags = data.centrotags ? data.centrotags : [];
        this.tags = data.projecttags ? data.projecttags : [];
        this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith(null),
        map((tag: string | null) => (tag ? this._filter(tag) : data.centrotags.slice())),);
    }

    add(event: MatChipInputEvent): void 
    {
        const value = (event.value || '').trim();

        console.log('----add funciotn')
        if (value && this.centrotags.includes(value)) 
        {
            this.tags.push(value);
        }

        // Clear the input value
        event.value = '';
        this.tagCtrl.setValue(null);
    }

    remove(tag: string): void 
    {
        const index = this.tags.indexOf(tag);
        console.log('----remove funciotn')
        if (index >= 0) 
        {
            this.tags.splice(index, 1); 
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void 
    {
        this.tags.push(event.option.viewValue);
        this.tagInput.nativeElement.value = '';
        this.tagCtrl.setValue(null);
    }

    private _filter(value: string): string[] 
    {
        const filterValue = value.toLowerCase();

        return this.centrotags.filter(tag => tag.toLowerCase().includes(filterValue));
    }

    onNoClick(): void 
    {
        this.dialogRef.close();
    }
}
