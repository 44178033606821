import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../controller/database.service'


@Component({
  selector: 'app-adminprojects',
  templateUrl: './adminprojects.component.html',
  styleUrls: ['./adminprojects.component.css']
})
export class AdminprojectsComponent implements OnInit {

  allProjects = null;
  allUsers = null;

  deletingUser;
  deleting = false;

  constructor(public db: DatabaseService) { }

  ngOnInit() {

    this.db.subscribeToUsers().subscribe(usrs => {
      this.allUsers = usrs;
      this.db.subscribeToProyectos().subscribe(proys => {
        this.allProjects = proys;

        for (let i = 0; i < this.allUsers.length; i++) {
          this.allUsers[i].proyectos = [];

          if (this.allUsers[i].centro && this.allUsers[i].centro != '') {
            this.db.getCentroById(this.allUsers[i].centro).subscribe(c => {
              if (c) {
                this.allUsers[i].centro_name = c.nombre ? c.nombre : '';
              } else {
                this.allUsers[i].centro_name = '';
              }
            })
          } else {
            this.allUsers[i].centro_name = '';
          }
        }

        console.log(this.allProjects);
        this.allProjects.forEach(pr => {
          for (let i = 0; i < this.allUsers.length; i++) {
            if (pr.id_user === this.allUsers[i]._id) {
              this.allUsers[i].proyectos.push(pr);
            }
          }
        });
      });
    });
  }

  deleteProjects(user) {
    this.deletingUser = user;
    this.deleting = true;
  }

  confirmDeleteProjects() {
    this.deletingUser.proyectos.forEach(pr => {
      this.db.deleteProject(pr._id);
    })
    this.allUsers.proyectos = [];
    this.deleting = false;
  }

  sortProjectsBy(prop) {
    this.allUsers.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1)
  }

}
